
/**
 * Overrides the Plotly React component so that they can find the correct version
 * of the Plotly library.
 * 
 * Thanks: https://github.com/plotly/react-plotly.js/issues/143#issuecomment-593555917
 */

import plotComponentFactory from "react-plotly.js/factory";
import Plotly from "plotly.js-dist";

const Plot = plotComponentFactory(Plotly);

export default Plot;
