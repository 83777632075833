
import { Fragment } from "react";


/**
 * Defines how sections in the fact sheet page should be rendered.
 */
export function FactSheetSection(props) {
  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-lg-3">
          <h4>
            { props.name }
            <br/>
            { props.subtitle ? <small className="text-muted mr-2">{ props.subtitle }</small> : null }
          </h4>
        </div>
        <div className="col-lg-9">
          { props.children }
        </div>
      </div>
    </Fragment>
  )
}


/**
 * Defines how to render the main fact sheet section.
 */
export function FactSheet(props) {
  return (
    <Fragment>
      <h1>{props.name} Facts</h1>
  
      { props.children }
    </Fragment>
  )
}
