
import { FactSheet, FactSheetSection } from "./FactsSheet";
import { EddmapsSection, EddmapsIframe } from "../Eddmaps/Images";


/**
 * Displays the fact sheet for Wheat Rust.
 */
export default function WheatRustKansasFactSheet(props) {
  return <FactSheet name="Wheat Rust">
    <FactSheetSection name="Pathogen">
      Wheat rust is one of the oldest crop diseases in the world. There are three types of rust: stem rust, leaf rust, and yellow/stripe rust, caused by the “rust fungus” <i>Puccinia</i>. <i>Puccinia graminis</i> f. sp. <i>tritici</i> causes stem rust (also known as black rust). Leaf rust is caused by <i>Puccinia triticina</i> and the stripe/yellow rust is caused by <i>Puccinia striiformis</i>. AIDO4plants will be using term yellow rust to refer to this disease.
    </FactSheetSection>

    <EddmapsSection title="Wheat Rust Images">
      <div className="row">
        <div className="col-xl-6">
          <EddmapsIframe diseaseName="Wheat Leaf Rust" url="https://images.bugwood.org/gallery.cfm?coll=110981&color=transparent&notitle"/>
        </div>
        <div className="col-xl-6">
          <EddmapsIframe diseaseName="Wheat Stem Rust" url="https://images.bugwood.org/gallery.cfm?coll=110358&color=transparent&notitle"/>
        </div>
      </div>
    </EddmapsSection>

    <FactSheetSection name="Host and reservoir">
      The primary host for the rust fungus is wheat, but it may also affect susceptible cultivars of barley, barley, triticale, and some species of goatgrass and wheatgrass.
    </FactSheetSection>

    <FactSheetSection name="Transmission">
      The spores are windborne and travel with storm systems. Under optimal conditions for disease development, infection is completed in 6–8 hours and uredospores capable of causing secondary spread of the disease are produced in 7–10 days. Vast quantities of spores are produced allowing extremely efficient airborne disease spread.
    </FactSheetSection>

    <FactSheetSection name="Disease symptoms" subtitle="Onset, duration and characteristic features">
      The leaf, stem, and stripe/yellow rust are distinguished from each other based on the size, color, as well as the arrangement of the pustules on the affected plant surface. All rusts are characterized by the rusty colored pustules, please visit this <a target="_blank" href="https://ohioline.osu.edu/factsheet/plpath-cer-12">website</a><sup>1</sup> for images of leaf, stem, stripe/yellow rust and detailed descriptions about onset, duration of the disease and characteristic features.
    </FactSheetSection>

    <FactSheetSection name="Epidemiology and risk factors">
      A map showing wheat-producing regions of the world is given <a target="_blank" href="https://www.mapsofworld.com/world-top-ten/world-map-countries-wheat-production.html">here</a><sup>2</sup> and the map of wheat producing regions of the United States are given <a target="_blank" href="https://www.wheatworld.org/wheat-101/wheat-production-map/">here</a><sup>3</sup>. The fungal spores are carried by wind and storms, a map showing the Puccinia pathway for United States is available <a target="_blank" href="https://www.ars.usda.gov/midwest-area/stpaul/cereal-disease-lab/docs/cereal-rust-bulletins/cereal-rust-bulletins/">here</a><sup>4</sup>. Temperate regions of the world have a higher occurrence of stripe/yellow rust, and a higher incidence occurs in US, China and Australia. A map showing stripe/yellow rust incidence is shown <a target="_blank" href="https://striperust.wsu.edu/disease-info/distribution/">here</a><sup>5</sup>. No variety of wheat are immune to all rust fungi races, new races arise every few years requiring change in variety of wheat cultivated. The average life span of a rust resistant variety is about 2-4 years.
    </FactSheetSection>

    <FactSheetSection name="Diagnosis">
      Rust diseases are identified based on color, shape and arrangement of pustules on wheat. Details regarding diagnosis of <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-leaf-rust-wheat">leaf rust</a><sup>6</sup>, <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-stem-rust-wheat">stem rust</a><sup>7</sup>, and&nbsp; <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-stripe-rust-wheat">stripe rust</a><sup>8</sup> are available from these websites.
    </FactSheetSection>

    <FactSheetSection name="Infectious agent genotype and phenotype information">
      Virulence surveys are conducted annually to inform the breeding programs to develop rust resistant varieties. The ability to infect a standard set of wheat differential lines determines the virulence of the fungi. Multilocus genotyping (MLG) using a standard set of simple sequence repeat (SSR) markers is used to determine the race of the pathogen. Further information on rust MLGs and their worldwide distribution is described in this <a target="_blank" href="https://apsjournals.apsnet.org/doi/full/10.1094/PHYTO-10-18-0411-R">publication</a><sup>9</sup>.
    </FactSheetSection>

    <FactSheetSection name="Infection or life cycle of the pathogen">
      Rust fungi have complex life cycles with different host plants and up to five different spore stages. In all cases, the economic host is wheat and the alternate host is typically a weed or native plant. Barberry (<i>Berberis vulgaris</i>) is the primary alternate host for the stem rust fungus. Alternate hosts for leaf rust, include meadow rue, rue anemone, and Clematis. There are no known alternate hosts for Stripe/Yellow rust, this rust fungus has a modified life cycle with only one required host. Information on the life cycle of <i>Puccinia triticina</i> can be found <a target="_blank" href="https://aces.nmsu.edu/pubs/_a/A415/welcome.html">here</a><sup>10</sup>. Epidemics of wheat rust can develop rapidly because uredospores produced on the wheat can cause autoinfection (uredospores infect the same plant that produced them) in stage of life cycle known as repeating stage10.
    </FactSheetSection>

    <FactSheetSection name="Treatment and control">
      Four major recommendations are made for management of wheat rust:
      <ol>
        <li>Grow rust resistant varieties suitable for a region</li>
        <li>Adjust planting time to decrease rust occurrence based on the climate and weather pattern for a region</li>
        <li>Adequate and balanced fertilization based on soil tests</li>
        <li>Fungicide application is recommended if the variety is susceptible, disease occurs early in the season, and if there is a danger of flag leaf getting infected.</li>
      </ol>
    </FactSheetSection>

    <FactSheetSection name="Notes">
      Crop loss due to rust depends on severity of the rust as well as the growth stage of the plant. A severe rust on flag leaf leads to increased loss in yield. Infection during flowering also causes increased crop loss. Global wheat rust monitoring data can be accessed at <a target="_blank" href="https://rusttracker.cimmyt.org/?page_id=305">RustTracker.org</a><sup>11</sup>.
    </FactSheetSection>

    <FactSheetSection name="Sources">
      <ol>
        <li>
          <i>Rust Diseases of Wheat.</i> (n.d.). Retrieved April 27, 2021, from <a target="_blank" href="https://ohioline.osu.edu/factsheet/plpath-cer-12">https://ohioline.osu.edu/factsheet/plpath-cer-12</a>
        </li>

        <li>
          <i>World map showing top ten countiries ny wheat production in the world</i> <a target="_blank" href="https://www.mapsofworld.com/world-top-ten/world-map-countries-wheat-production.html">https://www.mapsofworld.com/world-top-ten/world-map-countries-wheat-production.html</a> (Accessed March 25, 2022)
        </li>

        <li>
          Wheat grown by region, wheat production map <a target="_blank" href="https://wheatworld.org/wheat-101/wheat-production-map/">https://wheatworld.org/wheat-101/wheat-production-map/</a> (Accessed March 25, 2022)
        </li>
        
        <li>
          <i>Cereal Rust Bulletins : USDA ARS.</i> (n.d.). Retrieved April 27, 2021,
          from <a target="_blank" href="https://www.ars.usda.gov/midwest-area/stpaul/cereal-disease-lab/docs/cereal-rust-bulletins/cereal-rust-bulletins/">https://www.ars.usda.gov/midwest-area/stpaul/cereal-disease-lab/docs/cereal-rust-bulletins/cereal-rust-bulletins/</a>
        </li>

        <li>
          Stripe Rust Distribution. Washington State University extension resources for growers and researchers. <i><a target="_blank" href="https://striperust.wsu.edu/disease-info/distribution/">https://striperust.wsu.edu/disease-info/distribution/</a></i> (Accessed March 25, 2022)
        </li>

        <li>
          Diagnosis of leaf rust of wheat. Department of Primary Industries and Regional Development. Government of Western Australia <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-leaf-rust-wheat">https://www.agric.wa.gov.au/mycrop/diagnosing-leaf-rust-wheat</a> (Accessed March 25, 2022)
        </li>

        <li>
          Diagnosis of stem rust of wheat. Department of Primary Industries and Regional Development. Government of Western Australia <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-stem-rust-wheat">https://www.agric.wa.gov.au/mycrop/diagnosing-stem-rust-wheat</a> (Accessed March 25, 2022)
        </li>

        <li>
          Diagnosis of stripe rust of wheat. Department of Primary Industries and Regional Development. Government of Western Australia <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-stripe-rust-wheat">https://www.agric.wa.gov.au/mycrop/diagnosing-stripe-rust-wheat</a> (Accessed March 25, 2022)
        </li>

        <li>
          Kolmer, J. A., Ordoñez, M. E., German, S., Morgounov, A., Pretorius, Z., Visser, B., Goyeau, H., Anikster,
          Y., &#38; Acevedo, M. (2019). Multilocus Genotypes of the Wheat Leaf Rust Fungus Puccinia <i>triticina</i>
          in Worldwide Regions Indicate Past and Current Long-Distance Migration.
          Phytopathology®, 109(8), 1453–1463. <a target="_blank" href="https://apsjournals.apsnet.org/doi/full/10.1094/PHYTO-10-18-0411-R">https://apsjournals.apsnet.org/doi/full/10.1094/PHYTO-10-18-0411-R</a>
        </li>

        <li>
          <i>NMSU: Leaf, Stem and Stripe Rust Diseases of Wheat.</i> (n.d.). Retrieved April 26, 2021,
          from <a target="_blank" href="https://aces.nmsu.edu/pubs/_a/A415/welcome.html">https://aces.nmsu.edu/pubs/_a/A415/welcome.html</a>
        </li>

        <li>
          <i>Survey Mapper | RustTracker.org.</i> (n.d.). <a target="_blank" href="https://rusttracker.cimmyt.org/?page_id=305">https://rusttracker.cimmyt.org/?page_id=305</a> (Accessed March 25, 2022)
        </li>
      </ol>
    </FactSheetSection>
  </FactSheet>
}
