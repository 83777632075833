
import { Fragment } from "react";

import imgFactSheet from "../images/fact_sheet.png";
import imgDataEntryForm from "../images/data_entry_form.png";
import imgSearchResults from "../images/search_results.png";

export default function TutorialPage() {
  return (
    <Fragment>
      <div className="row" style={{ marginTop: "90px" }}>
        <div className="col-lg-2"/>
        <div className="col-lg-8">
          <h1>AIDO4Crops Tutorial</h1>

          <p>
            AIDO4Crops is an analytic tool that is designed to allow users to quickly find historical trends in
            plant diseases that are similar to their ongoing situation. The tool is based on the original
            AIDO (Analytics for Investigation of Disease Outbreaks), which was built to support human diseases.
          </p>

          <hr/>

          <div id='intro-section-1' className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">
                Overview
              </h4>
            </div>

            <div className="card-body">
              On the home page, you'll see a drop down menu that contains the list of diseases that
              are currently supported in AIDO4Crops. Selecting one of these disease will take you
              to the fact sheet page for that disease.

              Each disease fact sheet contains detailed information about the disease you selected,
              including symptoms, transmission, diagnosis information, and much more. If you aren't
              already familiar with the disease, you can use the fact sheet page to gain a basic
              understanding of its characteristics.

              <img className="mx-auto d-block mt-4" src={ imgFactSheet } style={{ maxWidth: "100%" }}/>
            </div>
          </div>

          <div id='intro-section-1' className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">
                Searching for Historical Disease Records
              </h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-8">
                  On the left side of the fact sheet page is the input form that should be used to perform
                  a search across the historical disease library for the selected disease. Each field in
                  the input form represents a property that AIDO4Crops uses when determining the similarity 
                  of your input to the historical library. Each property includes help text and a list of
                  data sources that you can use to find the correct value for each field for your current
                  disease event.

                  After you've filled in as many fields as you can, scroll to the bottom of the input
                  form and click the search button.
                </div>
  
                <div className="col-lg-4">
                  <img className="mx-auto d-block" src={ imgDataEntryForm } style={{ maxWidth: "100%" }}/>
                </div>
              </div>
            </div>
          </div>

          <div id='intro-section-1' className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">
                Understanding the search results
              </h4>
            </div>

            <div className="card-body">
              Once you've run a search, you'll see a list of historical disease occurrences, ranked based
              on their similarity to the parameters you entered. 

              <ol>
                <li>
                  On the right side bar, you'll see the list of all disease records that are shown on
                  the current page, along with the similarity score that each record received.
                </li>
                <li>
                  Each disease record contains a number of visualizations showing the progression of the
                  disease over both time and space for this record. You can switch between different
                  visualizations using the tabs at the top of each record.
                </li>
                <li>
                  Each record contains detailed contextual information about that disease record.
                  The "Features" tab contains a description of the record, including distinctive features,
                  contextual information, and any actions or control measures that were taken. The
                  "Properties" tab shows how the similarity algorithm calculated the score for the record.
                  Finally, the "Miscellaneous" tab contains additional metadata about the record, including
                  data sources and references.
                </li>
              </ol>

              <img className="mx-auto d-block mt-4" src={ imgSearchResults } style={{ maxWidth: "100%" }}/>
            </div>
          </div>
        </div>
        <div className="col-lg-2"/>
      </div>
    </Fragment>
  )
}
