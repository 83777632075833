
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import LoadingSpinner from "../utils/LoadingSpinner";
import { selectDisease } from "../redux/diseases/Actions";


/**
 * Defines the form that allows the user to select a particular
 * disease.
 */
class DiseaseForm extends Component {
  componentDidMount() {
    // Get the list of values that are explictely set on the URL bar.
    const urlParams = new URLSearchParams(this.props.location.search);

    if(urlParams.has("diseaseId") && this.props.selectedDisease === null) {
      // If the disease has not yet been set in the redux state but it is set in the URL,
      // set the current value based on what's in the URL bar.
      this.changeSelection(urlParams.get("diseaseId"));
    }
  }

  render() {
    if(this.props.diseases === null) {
      return this.render_loading()
    } else {
      return this.render_form();
    }
  }

  /**
   * Displays the loading bar when we're loading information about the diseases
   * that can be selected.
   */
  render_loading() {
    return <LoadingSpinner/>
  }

  /**
   * Renders the full selection form.
   */
  render_form() {
    // Convert the raw disease data to the list of form options that the
    // user can select.
    const options = Object.values(this.props.diseases).map((curr_disease) => (
      <option
        value={ curr_disease.ref_id }
        key={ `disease-selection-${curr_disease.ref_id}` }
      >
        { curr_disease.display_name }
      </option>
    ));
    
    return (
      <div className="form-group">
        <label className="text-muted" htmlFor="disease_selector">Disease</label>
        <select
          className="form-control"
          id="disease_selector"
          value={
            this.props.selectedDisease === null
            ? ""  // No option is selected
            : this.props.selectedDisease  // Specify which disease should be selected in the form.
          }
          onChange={
            (event) => {
              const diseaseId = event.target.value;
              this.changeSelection(diseaseId);

              // If the user selects a different disease using the UI widget, not only
              // do we want to set the selected disease in state, but we also want to go
              // to the next page.
              var params = new URLSearchParams({diseaseId}).toString();
              this.props.history.push({pathname: `/search`, search: params});
            }
          }
        >
          <option value="">
            ---
          </option>
          { options }
        </select>
      </div>
    )
  }

  /**
   * Defines how to change the selected disease when the user changes the form field selection.
   */
  changeSelection(diseaseId) {
    this.props.selectDisease(diseaseId);

    if(diseaseId === "" || diseaseId === null) {
      // No disease is selected. The user requested to go to the home page.
      this.props.history.push({pathname: `/`});
      return 
    }
  }
}


function mapStateToProps(state) {
  return {
    diseases: state.diseasesReducer.diseases,
    selectedDisease: state.diseasesReducer.selectedDisease,
  };
};

export default connect(
  mapStateToProps,
  { selectDisease }
)(withRouter(DiseaseForm));
