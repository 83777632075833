
import { Component, Fragment } from "react";

const MILLISECONDS_PER_MONTH = (
  4  // 4 weeks per month
  * 7  // 7 days per week
  * 24  // 24 hours per day
  * 60  // 60 minutes per hour
  * 60  // 60 seconds per minute
  * 1000  // 1000 milliseconds per second
);


/**
 * Defines how to render a slider that allows the userto select values
 * from a time region of a series.
 */
export class SeriesSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMaxDate: null
    };
  }

  render() {
    const {
      id,
      onChange,
      series,
      maxDate,
      minDate,
      ...rest
    } = this.props;

    const numMonths = Math.ceil((maxDate - minDate) / MILLISECONDS_PER_MONTH);

    const extraProps = {
      // If there is only one month's worth of data, do not enable
      // the slider
      disabled: numMonths === 0
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            <label className="text-muted" htmlFor={ id }>Time period</label>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <input
              type="range"
              className="custom-range"
              style={{ width: "100%" }}
              id={ id }
              onChange={ (event) => { this.onSlide(event, onChange); } }
              min={ 0 }
              max={ numMonths }
              { ...extraProps }
              { ...rest }
            />
          </div>
        </div>

        { this.renderSelectedDates() }
      </Fragment>
    )
  }

  /**
   * Displays information to the user about the current dates that are selected.
   */
  renderSelectedDates() {
    let noSeriesIsSelected = this.props.minDate === null || this.props.maxDate === null;
    if(noSeriesIsSelected) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-lg-4">
          <p className="text-muted" htmlFor={ this.props.id }>Start: { this.dateToString(this.props.minDate) }</p>
        </div>
        <div className="col-lg-4">
          <p className="text-muted text-center" htmlFor={ this.props.id }>
            Displaying until: {
              this.state.selectedMaxDate !== null ?
              this.dateToString(this.state.selectedMaxDate) :
              this.dateToString(this.props.maxDate)
            }
          </p>
        </div>
        <div className="col-lg-4">
          <p className="text-muted text-right" htmlFor={ this.props.id }>End: { this.dateToString(this.props.maxDate) }</p>
        </div>
      </div>
    )
  }

  /**
   * Format dates consistently.
   */
  dateToString(date) {
    return date.toISOString().substring(0, 7);
  }

  /**
   * Overrides the `onChange` event that the user passed in so that
   * it is given not only the original javascript event but also the range
   * of dates that are currently selected in this slider.
   * 
   * `event` is the original JavaScript event.
   * `then` is the callback that should be called with the new data (e.g.,
   *    the original `onChange` event).
   */
  onSlide(event, then) {
    const selectedMaxDate = new Date(this.props.minDate.getTime() + event.target.value * MILLISECONDS_PER_MONTH);
    this.setState({selectedMaxDate});

    then(event, this.props.minDate, selectedMaxDate);
  }
}
