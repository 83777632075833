
import LinkButton from "../utils/LinkButton";


/**
 * Main entrypoint for draing the app.
 * 
 * Original bootstrap template example taken from: https://getbootstrap.com/docs/4.0/examples/sticky-footer-navbar/
 */
function Header() {
  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <LinkButton className="navbar-brand btn btn-link" to="/">AIDO4Crops</LinkButton>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav mr-auto">
          <li className="nav-link">
            <LinkButton className="navbar-brand btn btn-link float-right" to="/tutorial"><small>Tutorial</small></LinkButton>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
