
import axios from 'axios';

import { setPage } from "../diseaseRecords/Actions";

const ACTION_NS = "diseases";
export const REQUEST_DATA = `${ACTION_NS}/request_data`;
export const SET_DISEASES = `${ACTION_NS}/set_diseases`;
export const SELECT_DISEASE = `${ACTION_NS}/select_disease`;
export const SET_PROP_DEFNS = `${ACTION_NS}/set_prop_defns`;
export const SET_FORM_VALUES = `${ACTION_NS}/set_form_values`;


/**
 * Announces the list of diseases based on the raw data provided by the API.
 */
export function announceDiseases(cropDiseaseData) {
  return {
    type: SET_DISEASES,
    payload: cropDiseaseData
  }
}


/**
 * Selects a different active disease.
 * 
 * `selectedDiseaseId` is the ID of the disease that should be selected.
 */
export function selectDisease(selectedDiseaseId) {
  return async (dispatch, getState) => {
    // Form fields cannot have a value of NULL, so we need to use the empty string. So,
    // here we assume that NULL and the empty string are equivalent.
    if(selectedDiseaseId === "") {
      selectedDiseaseId = null;
    }

    // Update the data store with the disease that is currently selected.
    dispatch({
      type: SELECT_DISEASE,
      payload: selectedDiseaseId
    });

    if(selectedDiseaseId === null) {
      // The user has deselected all diseases. There's nothing
      // else that we need to do.
      return
    }

    let currState = getState();
    if(currState.diseasesReducer.diseases === null) {
      // We've been asked to select a disease, but the list of available diseases does
      // not exist yet. This happens when the use refreshes the page - causing us to lose
      // all state except what is placed on the URL. So, re-collect the disease list.
      await getDiseases()(dispatch, getState);
      // `getDiseases` will call back into `selectDisease` with the updated state. So, this
      // particular call does not need to do anything else.
    }

    // Request the details for this disease.
    currState = getState();
    const currDiseaseDetails = await axios.get(
      currState.diseasesReducer.diseases[currState.diseasesReducer.selectedDisease].url
    )

    // Set the list of properties that are available in this disease.
    dispatch(announceDiseasePropertyDefinitions(currDiseaseDetails.data.properties))
  }
}


/**
 * Given a list of disease property defintions, store those
 * definitions in the redux store.
 */
export function announceDiseasePropertyDefinitions(definitions) {
  // Index definitions by ID.
  const normDefn = definitions.reduce(
    (map, currDefn) => { map[currDefn.id] = currDefn; return map;},
    {}
  );

  return {
    type: SET_PROP_DEFNS,
    payload: normDefn
  }
}


/**
 * Initiates the API requests needed in order to collect the data for this
 * disease.
 */
export function getDiseases() {
  return async (dispatch, getState) => {
    dispatch({type: REQUEST_DATA});

    let cropDiseaseData = await axios.get("/api/crop-diseases");
    let all_results = cropDiseaseData.data.results

    while(cropDiseaseData.data.next !== null) {

      // Get the next page of results
      cropDiseaseData = await axios.get(cropDiseaseData.data.next);
      // Add the new page of results to the list of paged results.
      all_results = [...all_results, ...cropDiseaseData.data.results]
    }

    // Normalize the crop disease data.
    // Index with respect to disease ID.
    const normDiseaseData = all_results.reduce(
      (map, currDisease) => { map[currDisease.ref_id] = currDisease; return map;},
      {}
    )

    // Store the list of available diseases
    dispatch(announceDiseases(normDiseaseData));
  }
}


/**
 * Sets/updates the values in the store for the current form.
 * 
 * Note that field-level state for the form is currently maintained in the
 * form itself rather than Redux. For the initial implementation at least,
 * we don't call this function for every change to the form. Rather, we
 * bulk-update the state when the user clicks the submit button.
 * 
 * `form` is a dictionary containing the values for each form field.
 */
export function runSearch(form) {
  return async (dispatch, getState) => {
    // Update the form parameters that should be sent to the server.
    dispatch({type: SET_FORM_VALUES, payload: form});

    // Move back to the first page.
    await setPage(1)(dispatch, getState);
  }
}
