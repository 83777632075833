
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Nav, NavDropdown } from 'react-bootstrap';

import { requestDiseaseRecordDetails } from "../redux/diseaseRecords/Actions";
import { toggleSeries, selectFocusSeries } from "../redux/series/Actions";
import { DATA_COLLECTION_STAGES } from "../redux/utils";
import LoadingSpinner from "../utils/LoadingSpinner";
import { SmallLoadingSpinner } from "../utils/LoadingSpinner";
import { OccurrencesMap, OccurrencesSeriesPoint } from "../Maps/Map";
import { SeriesSlider }  from "../utils/SeriesSlider";
import { OccurrencesTimeSeries } from "../TimeSeries/Occurrences";
import { getCompositeOccurrenceSeriesForRecord } from "../redux/composite_series/Actions";
import CompositeOccurrencesTimeSeries from "../TimeSeries/CompositeOccurrencesSeries";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { UnkownPropertyDefinition } from '../utils/Errors.js';
import ScoredPropertyView from "../PropertiesInspectors/ScoredPropertyView";
import UnscoredPropertyView from "../PropertiesInspectors/UnscoredPropertyView";
import { scoringAlgorithmStatus } from "../redux/diseaseRecords/Actions";


/**
 * Defines the UI components that should wrap the data in
 * a disease record. That is, this component defines the "border"
 * around the UI for a disease record.
 */
class _RecordCard extends Component {
  render() {
    const browseOnly = this.props.scoringResultsStatus === scoringAlgorithmStatus.BROWSE_ONLY

    let showScore = null;
    if(!browseOnly) {
      showScore = (
        <small className="text-muted"><SmallLoadingSpinner/></small>
      );
    }

    if(!browseOnly && this.props.scoringResultsStatus == scoringAlgorithmStatus.COMPLETE) {
      showScore = (
        <small className="text-muted">{ (this.props.recordSummary.score * 100).toFixed(2) }%</small>
      );
    }

    return (
      <div id={ `disease-record-${this.props.recordSummary.id}` } className="card mb-4 box-shadow">
        <div className="card-header">
          <h4 className="my-0 font-weight-normal">
            { this.props.recordSummary.name } { showScore }
          </h4>
        </div>

        <div className="card-body">
          { this.props.children }
        </div>
      </div>
    );
  }
}

const RecordCard = connect(
  (state) => {
    return {
      scoringResultsStatus: state.diseaseRecordsReducer.scoringStatus
    };
  }
)(_RecordCard);


/**
 * Defines the UI component that lets a user toggle whether a series is being displayed.
 */
class SeriesDisplayToggle extends Component {
  render() {
    const {
      isActive,
      title,
      color,
      ...rest
    } = this.props;

    return (
      <button
        type="button"
        className={ `btn btn-secondary ${ isActive ? "active" : null }` }
        // data-toggle="button"
        aria-pressed={ isActive }
        { ...rest }
      >
        {
          color !== null
          ? (
            <svg height="10" width="10">
              <circle cx="5" cy="5" r="5" fill={ color } />
            </svg>
          )
          : null
        }
        &nbsp;
        <span>
          { title }
        </span>
      </button>
    )
  }
}


/**
 * Defines how to render the data for a single disease record.
 */
class DiseaseRecord extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The maximum and minimum dates that should be
      // used when choosing the points that should be displayed
      // on the map.
      maxDate: null,
      minDate: null,
      // The visualization that's currently being displayed in this disease record
      selectedVisTab: "occurrencesMap"
    };
  }

  componentDidMount() {
    if(!this.props.recordDetails) {
      this.props.requestDiseaseRecordDetails(this.props.recordSummary.id);
    }
  }

  componentDidUpdate() {
    // If the user has selected one of the time series visualizations...
    if(this.state.selectedVisTab === "combinedOccurrencesSeries") {
      if(this.props.totalOccurrencesSeries === null) {
        // Get the data for the combined series.
        this.props.getCompositeOccurrenceSeriesForRecord(this.props.recordSummary.id);
      }
    } else if(this.state.selectedVisTab !== "occurrencesMap") {
      // The user has selected one of the time series detail views.
      // Look through the list of available time series data
      // to figure out which one the user selected.
      let seriesSpec = this.props.recordDetails.series.filter((series) => series.type === this.state.selectedVisTab)[0];
      // Check if we need to change the selected focus series
      if(this.props.focusSeries === null || this.state.selectedVisTab !== this.props.focusSeries["type"]) {
        // Specify which series the user wants to focus on.
        this.props.selectFocusSeries(this.props.recordSummary.id, seriesSpec);
      }
    }
  }

  render() {
    if(!this.props.recordDetails) {
      // We haven't loaded the details for this record yet (e.g., we're
      // stil waiting for a response from the server). Display a loading bar.
      return (
        <RecordCard recordSummary={ this.props.recordSummary }>
          <div className="row">
            <div className="col-lg-12">
              <LoadingSpinner/>
            </div>
          </div>
        </RecordCard>
      )
    }

    // This helps handle cases where there was incorrect data entered.
    // If the analyst entered a record that doesn't have any time series
    // attached to it, then `this.props.series` will be `null` even after
    // all server requests have been completed. In this case, we don't want
    // to display any timeseries-dependent visualizations.
    const recordHasTimeSeriesData = this.props.series !== null;
    // Choose the visualization that should be displayed
    let visToDisplay = null;
    if(recordHasTimeSeriesData) {
      if(this.state.selectedVisTab === "occurrencesMap") {
        visToDisplay = this.renderOccurrencesMap();

      } else if(this.state.selectedVisTab === "combinedOccurrencesSeries") {
        visToDisplay = this.renderCompositeOccurrencesSeries(this.props.totalOccurrencesSeries);
        
      } else {
        // Check if we need to change the selected focus series
        if(this.props.focusSeries === null || this.state.selectedVisTab !== this.props.focusSeries["type"]) {
          visToDisplay = (
            <div style={{ height: "400px" }}>
              <LoadingSpinner/>
            </div>
          );
        } else {
          // Build the visualization
          visToDisplay = (
            <div style={{ height: "400px" }}>
              { this.renderOccurrencesSeries(this.props.focusSeries) }
            </div>
          );
        }
      }
    }

    // Build the list of tabs that should be used to allow the user to switch between time series
    // visualizations.
    const seriesNavItems = this.props.recordDetails.series
      .map((series, i) => (
        <NavDropdown.Item key={ i } eventKey={ series.type }>
          { series.type }
        </NavDropdown.Item>
      ));

    return (
      <RecordCard recordSummary={ this.props.recordSummary }>
        {
          recordHasTimeSeriesData
          ? (
            <Fragment>
              <Nav variant="tabs" activeKey={ this.state.selectedVisTab } onSelect={ this.changeVizTab }>
                <Nav.Item>
                  <Nav.Link eventKey="occurrencesMap">Occurrences Map</Nav.Link>
                </Nav.Item>
                <NavDropdown title="Time Series" id="nav-dropdown">
                  <NavDropdown.Item eventKey="combinedOccurrencesSeries">
                    Occurrence Counts
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                  { seriesNavItems }
                </NavDropdown>
              </Nav>

              <div className="row mt-3">
                <div className="col-lg-12">
                  { visToDisplay }
                </div>
              </div>
            </Fragment>
          )
          : null
        }

        <div className="row mt-3">
          <div className="col-lg-12">
            { this.renderFeatureDisplay() }
          </div>
        </div>
      </RecordCard>
    )
  }

  /**
   * Checks whether there are any series data related to this record that
   * are in the process of loading.
   */
  seriesAreLoading() {
    const recordData = this.props.recordDetails;

    for(let memberSeries of recordData.series) {
      if(!this.props.series || !(memberSeries.id in this.props.series)) {
        // This series hasn't been initialized yet. For example,
        // this may be because the user hasn't requested this series
        // yet. Just skip it.
        continue;
      }

      if(this.props.series[memberSeries.id].requestState === DATA_COLLECTION_STAGES.collecting) {
        return true;
      }
    }

    return false;
  }

  /**
   * Render the widgets that let the user toggle between
   * series.
   */
  renderSeriesButtons() {
    return this.props.recordDetails.series
      .map((series, i) => (
        <SeriesDisplayToggle
          key={ i }
          onClick={ () => this.props.toggleSeries(this.props.recordSummary.id, series) }
          isActive={ this.props.series && series.id in this.props.series }
          title={ series.type }
          color={ this.props.series && series.id in this.props.series ? this.props.series[series.id].color : null}
        />
      ));
  }

  /**
   * Displays the text content for each of the properties that are assigned to this
   * record.
   */
  renderFeatureDisplay() {
    const featureProperties = [];
    const propertyProperties = [];
    const miscProperties = [];
    for(let property of this.props.recordDetails.properties) {
      if(!(property.definition in this.props.propertyDefinitions)) {
        throw new UnkownPropertyDefinition(
          `Unable to find a property with ID number ${property.definition}. Known properties are: ${Object.keys(this.props.propertyDefinitions)}`
        )
      }

      let pDefn = this.props.propertyDefinitions[property.definition]

      if(pDefn.ptype === "feature") {
        featureProperties.push(property)
      } else if(pDefn.ptype === "property") {
        propertyProperties.push(property)
      } else {
        miscProperties.push(property)
      }
    }

    return (
      <Fragment>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link active" id={ `record-${this.props.recordDetails.id}-features-tab` } data-toggle="tab" href={ `#record-${this.props.recordDetails.id}-features-content` } role="tab" aria-controls="features" aria-selected="true">Features</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id={ `record-${this.props.recordDetails.id}-properties-tab` } data-toggle="tab" href={ `#record-${this.props.recordDetails.id}-properties-content` } role="tab" aria-controls="properties" aria-selected="false">Properties</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id={ `record-${this.props.recordDetails.id}-misc-tab` } data-toggle="tab" href={ `#record-${this.props.recordDetails.id}-misc-content` } role="tab" aria-controls="miscellaneous" aria-selected="false">Miscellaneous</a>
          </li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane active" id={ `record-${this.props.recordDetails.id}-features-content` } role="tabpanel" aria-labelledby="features">
            <UnscoredPropertyView
              propertiesToRender={ featureProperties }
              propertyDefinitions={ this.props.propertyDefinitions }
            />
          </div>
          <div className="tab-pane pt-3" id={ `record-${this.props.recordDetails.id}-properties-content` } role="tabpanel" aria-labelledby="properties">
            <ScoredPropertyView
              propertiesToRender={ propertyProperties }
              recordSummary={ this.props.recordSummary }
              propertyDefinitions={ this.props.propertyDefinitions }
            />
          </div>
          <div className="tab-pane" id={ `record-${this.props.recordDetails.id}-misc-content` } role="tabpanel" aria-labelledby="miscellaneous">
            <UnscoredPropertyView
              propertiesToRender={ miscProperties }
              propertyDefinitions={ this.props.propertyDefinitions }
            />
          </div>
        </div>
      </Fragment>
    )
  }

  /**
   * Defines how to change between the different visualizations that can be shown in the record view.
   * 
   * `selected` is the key of the tab that has been selected.
   */
  changeVizTab = (selected) => {
    this.setState({
      ...this.state,
      selectedVisTab: selected
    })
  }

  /**
   * Defines how to draw the map that shows the occurrences series
   * that the user has selected.
   */
  renderOccurrencesMap() {
    // Will hold the list of point objects that should be displayed.
    const seriesMaps = [];
    if(this.props.series) {
      let seriesNumber = 0;
      for(let seriesData of Object.values(this.props.series)) {
        let points = [];

        for(let pointIdx in seriesData.data) {
          let pointData = seriesData.data[pointIdx];
          let dateOfPoint = new Date(pointData.date.date);

          // If either the min or max date are not defined, the slider widget has not yet stated the period
          // of time that the user has selected.
          let userHasNotSelectedATimePeriod = this.state.minDate === null || this.state.maxDate === null;

          if(userHasNotSelectedATimePeriod || (dateOfPoint >= this.state.minDate && dateOfPoint <= this.state.maxDate)) {
            // This data point is within the time period that the user selected. Add
            // it to the series.
            points.push(
              <OccurrencesSeriesPoint
                key={ `${seriesNumber}-${pointIdx}` }
                pointData={ pointData }
                seriesData={ seriesData }
              />
            )
          }
        }

        seriesMaps.push(points)

        seriesNumber++;
      }
    }

    return (
      <Fragment>
        <div className="row" style={{ height: "300px" }}>
          <div className="col-lg-12">
            <OccurrencesMap>
              <MarkerClusterGroup>
                { seriesMaps }
              </MarkerClusterGroup>
            </OccurrencesMap>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            { this.renderTimeSelector() }
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="btn-group" role="group" aria-label="Basic example">
              { this.renderSeriesButtons() }

              { this.seriesAreLoading()
                ? <button className="btn btn-secondary" disabled>Loading...</button>
                : null
              }
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  /**
   * Renders the widget that allows the user to select
   * a time period that should be displayed.
   */
  renderTimeSelector() {
    if(!this.props.series) {
      // No series data are visible right now. The user should not be able to
      // select a time period.
      return null;
    }

    // Determine the maximum and minimum time point that can be selected.
    // Only takes into account the time series that are currently visible.
    let minDate = null;
    let maxDate = null;
    for(let seriesData of Object.values(this.props.series)) {
      for(let pointData of seriesData.data) {
        let parsedDate = new Date(pointData.date.date);
        if(minDate === null || parsedDate < minDate) {
          minDate = parsedDate;
        }

        if(maxDate == null || parsedDate > maxDate) {
          maxDate = parsedDate;
        }
      }
    }

    return (
      <SeriesSlider
        id={`time-range-${this.props.recordSummary.id}`}
        onChange={ (_, minDate, maxDate) => {this.setState({minDate, maxDate})} }
        maxDate={ maxDate }
        minDate={ minDate }
      />
    );
  }

  /**
   * Renders the components needed to display the time series plot for
   * the occurrencess in this record.
   * 
   * `series` is the series data that should be displayed.
   */
  renderOccurrencesSeries(series) {
    return (
      <OccurrencesTimeSeries
        series={ series }
        title={ series.type }
      />
    )
  }

  /**
   * Renders the components needed to display the composite series view.
   */
  renderCompositeOccurrencesSeries(series) {
    if(series === null) {
      return <LoadingSpinner/>
    }

    return (
      <CompositeOccurrencesTimeSeries
        pointEstimate={ this.getPointEsimate() }
        series={ series }
        title="Total occurrence counts of all series in this record"
      />
    )
  }

  /**
   * Returns the user's point estimate.
   */
  getPointEsimate = () => {
    const dateOfRecordStart = Date.parse(this.props.recordDetails.start_date);

    const occurrenceCount = this.props.recordSummary.scoreBreakdown["occurrence count"].user_value;
    const duration = parseInt(this.props.recordSummary.scoreBreakdown["duration"].user_value);

    return {
      "occurrence count": occurrenceCount,
      "duration": dateOfRecordStart + duration
    }
  }
}


function mapStateToProps(state) {
  return {
    propertyDefinitions: state.diseasesReducer.propertyDefinitions
  };
};

export default connect(
  mapStateToProps,
  { requestDiseaseRecordDetails, toggleSeries, selectFocusSeries, getCompositeOccurrenceSeriesForRecord }
)(DiseaseRecord);
