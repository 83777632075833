
import { SET_AGGREGATE_RECORD_SERIES } from "./Actions";


var initialState = {
  // Will be a mapping from record ID to the cumulative series that have
  // been collected for that record.
  byRecordId: {
    // Will map the record ID to the cumulative series that was built from _every_
    // series in that record.
    countOfOccurrencesInAllSeries: {}
  }
}


function compositeSeriesReducer(state=initialState, action) {
  switch (action.type) {
    case SET_AGGREGATE_RECORD_SERIES:
      return {
        ...state,
        byRecordId: {
          ...state.byRecordId,
          countOfOccurrencesInAllSeries: {
            ...state.byRecordId.countOfOccurrencesInAllSeries,
            [action.recordId]: action.payload
          }
        }
      }
    default:
      return state;
  }
}

export default compositeSeriesReducer;
