
import { DATA_COLLECTION_STAGES } from "../utils";
import {
  REQUEST_DATA,
  SET_DISEASES,
  SELECT_DISEASE,
  SET_PROP_DEFNS,
  SET_FORM_VALUES
} from "./Actions";


var initialState = {
  // Indicates what data collection stage we're currently in.
  requestState: DATA_COLLECTION_STAGES.noRequest,

  // When data are loaded, this will be the list of available diseases.
  diseases: null,

  // Will be the ID of the disease that the user has selected.
  selectedDisease: null,

  // List of valid properties that may be used in the selected disease.
  propertyDefinitions: null,

  // Values that the user has entered for the form for the currently selected
  // disease
  form: {},

  // Describes whether the user has selected any search options. That is, should
  // we load scores or should we just let the user browse?
  searchOptionsWereSelected: false,
}


function diseasesReducer(state=initialState, action) {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        requestState: DATA_COLLECTION_STAGES.collecting
      }
    case SET_DISEASES:
      return {
        ...state,
        diseases: action.payload
      }
    case SELECT_DISEASE:
      const selectedDisease = action.payload;

      return {
        ...state,
        selectedDisease,
        // Clear out perviously known properties
        propertyDefinitions: null,
        // We've only cleared out the previous properties; nobody's
        // actually asked us to find out what the new properties are.
        // State that no request for the details has been made.
        requestState: DATA_COLLECTION_STAGES.noRequest
      }
    case SET_PROP_DEFNS:
      return {
        ...state,
        propertyDefinitions: action.payload,
        requestState: DATA_COLLECTION_STAGES.collected,
      }
    case SET_FORM_VALUES:
      let newForm = action.payload;
      let aFieldHasBeenFilled = false
      for (const [_, value] of Object.entries(newForm)) {
        if(value !== undefined && value !== null && value !== "") {
          aFieldHasBeenFilled = true;
          break;
        }
      }

      return {
        ...state,
        form: newForm,
        searchOptionsWereSelected: aFieldHasBeenFilled
      }
    default:
      return state;
  }
}

export default diseasesReducer;
