
import { Component } from "react";
import { connect } from "react-redux";

import { setPage } from "../redux/diseaseRecords/Actions";


class DiseaseRecordPaginator extends Component {
  render() {
    // Calculate the number of pages that are available
    const numPages = Math.ceil(this.props.page.numRecords / this.props.page.recordsPerPage) + 1;

    if(numPages === 1) {
      // There's only one page. No need to render the paginator.
      return null;
    }

    const pagers = [];
    for(let currPageNum = 1; currPageNum < numPages; currPageNum++) {
      pagers.push(
        <li key={ currPageNum } className={`page-item ${this.props.page.currPage === currPageNum ? "active" : null}`}>
          <a key={ currPageNum } className="page-link" href="#" onClick={ () => this.props.setPage(currPageNum) }>
            { currPageNum }
          </a>
        </li>
      )
    }

    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination">
        <li className={`page-item ${this.props.page.currPage === 1 ? "disabled" : null}`}>
            <a className="page-link" href="#" onClick={ () => this.props.setPage(this.props.page.currPage - 1) }>
              Previous
            </a>
          </li>
          { pagers }
          <li className={`page-item ${this.props.page.currPage === numPages - 1 ? "disabled" : null}`}>
            <a className="page-link" href="#" onClick={ () => this.props.setPage(this.props.page.currPage + 1) }>
              Next
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}


function mapStateToProps(state) {
  return {
    page: state.diseaseRecordsReducer.page,
  };
};

export default connect(
  mapStateToProps,
  { setPage }
)(DiseaseRecordPaginator);
