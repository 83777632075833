import { Fragment } from "react"


/**
 * Defines the primary wrapper for EDDMapS content.
 */
export function EddmapsSection(props) {
  return (
    <div className="jumbotron mt-3">
      <div className="row mb-3">
        <div className="col-lg-12">
          <h3>{ props.title }</h3>
          <small>Thanks to <a target="_blank" href="https://www.eddmaps.org/">EDDMapS</a> and their collaborators for the following images.</small>
        </div>
      </div>
      
      { props.children }
    </div>
  )
}

/**
 * Defines a wrapper around EDDMapS image iframes.
 */
export function EddmapsIframe(props) {
  return (
    <Fragment>
      <h5>{ props.diseaseName }</h5>

      <iframe
        src={ props.url }
        frameborder="0"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        height="500"
        width="500"
        scrolling="no"
        seamless
      />
    </Fragment>
  )
}
