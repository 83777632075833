
/**
 * Main entrypoint for draing the app.
 * 
 * Original bootstrap template example taken from: https://getbootstrap.com/docs/4.0/examples/sticky-footer-navbar/
 */
function Footer() {
  return null;
  // return (
  //   <footer className="footer">
  //     <div className="container">
  //       <span className="text-muted">Place sticky footer content here.</span>
  //     </div>
  //   </footer>
  // );
}

export default Footer;
