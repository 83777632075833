
import { FactSheet, FactSheetSection } from "./FactsSheet";


/**
 * Displays the fact sheet for Wheat Rust.
 */
export default function WheatBlotchFactSheet(props) {
  return <FactSheet name="Wheat Blotch - North Dakota Library">
    <FactSheetSection name="Pathogen">
      The three major leaf blotch diseases in wheat are Tan spot (or yellow leaf spot), Septoria blotch (or speckled leaf spot) and Stagonospora blotch. Spore shape and size can be used to differentiate between the three leaf blotches. Tan spot is caused by <i>Drechslera tritici-repentis</i>, the fungus is also referred as <i>Pyrenophora tritici-repentis</i>. <i>Zymoseptoria tritici</i> (previously known as <i>Septoria tritici</i>) causes Septoria blotch. Stagonospora blotch is caused by <i>Parastagonospora nodorum</i><sup>1</sup>.
    </FactSheetSection>

    <FactSheetSection name="Host and reservoir">
      All three pathogens can survive on wheat residues such as wheat stubble and wheat straw during the winter months. In addition, their mycelium can be found on alternate hosts (rye, barley, wild grass, and volunteer wheat). The fungi can also survive on soil surfaces.
    </FactSheetSection>

    <FactSheetSection name="Transmission">
      Seed borne infections for <i>P. nodorum</i> are very common resulting in Staganospora blotch in seedlings. Infection from seeds is less common for the other two fungi; here transmission is mostly from soil inoculum. Wet windy weather is favorable to growth and infection by all three fungi. During wet weather the fungi spread easily from lower leaves to upper leaves.
    </FactSheetSection>

    <FactSheetSection name="Disease symptoms" subtitle="Onset, duration and characteristic features">
      Stagonospora blotch and tan spot are very similar in appearance. Tan to brown spot or flecks on leaf and/or glume characterize the tan spot diseases. Well-developed lesions may collapse during advanced stages of the disease. Stagonospora blotch typically appears two-three weeks after head emergence on upper leaves. Even though both diseases may affect the wheat plants anytime during the growth, they typically affect during later stages of growth. The major difference between the symptoms between these two fungi is in pycnidia production, however this is difficult to observe and may not occur during dry season. The predominant distinguishing factor for Septoria blotch is small black pycnidia produced during advanced stages of fungal infection. Septoria blotch also occurs usually on lower leaves. More information on all three pathogens and the symptoms can be found in the document titled “Fungal Leaf Spot Diseases of Wheat: Tan spot, Septoria/Stagonospora nodorum blotch and Septoria tritici blotch” prepared by North Dakota State University.<sup>2</sup>
    </FactSheetSection>

    <FactSheetSection name="Epidemiology and risk factors">
      Wet climatic conditions favor disease development. Septoria blotch occurs under cooler temperatures 50° to 68°F (15° to 20°C) and Stagonospora blotch occurs with warmer temperatures (68° to 81°F (20° to 27°C)). Tan spot development can occur under a wide range of temperatures when the moisture is not a limiting factor.
    </FactSheetSection>

    <FactSheetSection name="Diagnosis">
      The specific fungal infection can be diagnosed based on growth stage of the wheat, location of the lesion, and characteristics of the lesion. Details on diagnosing Septoria blotch can be found <a href="https://www.agric.wa.gov.au/mycrop/diagnosing-septoria-tritici-wheat" target="_blank">here</a><sup>3</sup>. Diagnostic criteria for tan spot can be found <a href="https://www.agric.wa.gov.au/mycrop/diagnosing-yellow-spot-wheat" target="_blank">here</a><sup>4</sup>. Differentiating between Septoria blotch and Stagonospora blotch is difficult and often requires microscopic observations.
    </FactSheetSection>

    <FactSheetSection name="Infectious agent genotype and phenotype information">
      <i>Pyrenophora tritici-repentis</i> that causes tan spot produces host selective toxins and is divided into eight races. Race 1 is the predominant pathogen in Great plains of the United States as well as most wheat growing regions of the world, please refer to <a href="https://www.apsnet.org/edcenter/disandpath/fungalasco/pdlessons/Pages/TanSpot.aspx" target="_blank">this website</a><sup>5</sup> for further details. A detailed description of <i>Parastagonospora nodorum</i> infection during various growth stages can be found <a href="https://www.apsnet.org/edcenter/disandpath/fungalasco/pdlessons/Pages/SeptoriaNodorum.aspx" target="_blank">here</a><sup>6</sup>.
    </FactSheetSection>

    <FactSheetSection name="Infection or life cycle of the pathogen">
      Infection of all three fungi can originate from contaminated seeds or pathogen overwintering in wheat stubble and mycelium in volunteer plants. Wind and water disperse the spores to growing wheat plants. A diagram of the leaf blotch life cycle is available <a href="https://ohioline.osu.edu/factsheet/plpath-cer-07" target="_blank">here</a><sup>1</sup>.
    </FactSheetSection>

    <FactSheetSection name="Treatment and control">
      Crop rotation, destroying alternate hosts (volunteer wheat, rye, barley, wild grasses) before planting, selection of varieties resistant to fungal pathogens, and planting using certified disease-free seedlings are recommended. Fungicide application during early or late stage can be adopted, if needed. More detailed recommendations are provided by Ohio State University <a target="_blank" href="https://ohioline.osu.edu/factsheet/plpath-cer-07">here</a><sup>1</sup>.
    </FactSheetSection>

    <FactSheetSection name="Notes">
      Development and adoption of resistant varieties is crucial for disease management.
    </FactSheetSection>

    <FactSheetSection name="Sources">
      <ol>
        <li>Leaf Blotch Diseases of Wheat—Septoria tritici Blotch, Stagonospora nodorum Blotch and Tan Spot <a target="_blank" href="https://ohioline.osu.edu/factsheet/plpath-cer-07">https://ohioline.osu.edu/factsheet/plpath-cer-07</a> (accessed 2021-07-29).</li>
        <li>Friskop, A.; Liu, Z. Fungal Leaf Spot Diseases of Wheat:Tan Spot, Septoria/Stagonospora Nodorum Blotch and Septoria Tritici Blotch. NDSU Extention Services May 2016.</li>
        <li>Diagnosing septoria tritici of wheat <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-septoria-tritici-wheat">https://www.agric.wa.gov.au/mycrop/diagnosing-septoria-tritici-wheat</a>(accessed 2021-07-30).</li>
        <li>Diagnosing yellow spot of wheat <a target="_blank" href="https://www.agric.wa.gov.au/mycrop/diagnosing-yellow-spot-wheat">https://www.agric.wa.gov.au/mycrop/diagnosing-yellow-spot-wheat</a> (accessed 2021-07-30).</li>
        <li>Tan spot of cereals <a target="_blank" href="https://www.apsnet.org/edcenter/disandpath/fungalasco/pdlessons/Pages/TanSpot.aspx">https://www.apsnet.org/edcenter/disandpath/fungalasco/pdlessons/Pages/TanSpot.aspx</a> (accessed 2021-07-30).</li>
        <li>Septoria nodorum blotch of wheat <a target="_blank" href="https://www.apsnet.org/edcenter/disandpath/fungalasco/pdlessons/Pages/SeptoriaNodorum.aspx">https://www.apsnet.org/edcenter/disandpath/fungalasco/pdlessons/Pages/SeptoriaNodorum.aspx</a> (accessed 2021-07-30).</li>
      </ol>
    </FactSheetSection>
  </FactSheet>
}
