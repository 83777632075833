
import { FactSheet, FactSheetSection } from "./FactsSheet";
import { EddmapsSection, EddmapsIframe } from "../Eddmaps/Images";


/**
 * Displays the fact sheet for Corn Tar Spot.
 */
export default function CornTarSpotFactSheet(props) {
  return <FactSheet name="Corn Tar Spot">
    <FactSheetSection name="Pathogen">
      The fungus <i>Phyllachora maydis</i> causes tar spot disease in susceptible varieties of corn. In South America, <i>P. maydis</i> forms a disease complex with <i>Monographella maydis</i> and forms “fisheye” lesions. <i>P. maydis</i> is a relatively new pathogen in the United States and <i>M. maydis</i> has not been detected in the US.
    </FactSheetSection>

    <EddmapsSection title="Corn Tar Spot Images">
      <div className="row">
        <div className="col-lg-6">
          <EddmapsIframe diseaseName="Tar Spot of Corn" url="https://images.bugwood.org/gallery.cfm?coll=108911&color=transparent&notitle"/>
        </div>
      </div>  
    </EddmapsSection>

    <FactSheetSection name="Host and reservoir">
      The fungal pathogen survives the winter in left over infested crop residues and on soil surfaces. Presence of other host plants are currently unknown.
    </FactSheetSection>

    <FactSheetSection name="Transmission">
      After rain or periods of high humidity, the stromata extrude spores which accumulate in a gelatinous mass on the stromata. In turn, rain and wind disperse these spores that can travel up to 80 yards.
    </FactSheetSection>

    <FactSheetSection name="Disease symptoms" subtitle="Onset, duration and characteristic features">
      Tar spot or fungal fruiting structures called ascomatum appear as small, raised, black spots scattered across the upper and lower leaf surfaces and even on husks and leaf sheaths during severe infections. Sausage shaped spore cases (asci) are visible under microscope. Characteristic images of tar spot on corn are available at <a target="_blank" href="https://cropprotectionnetwork.org/resources/articles/diseases/tar-spot-of-corn">this website</a><sup>1</sup>. This fungus can infect corn at any growth stage, symptoms may appear as early as third leaf growth stage.
    </FactSheetSection>

    <FactSheetSection name="Epidemiology and risk factors">
      Tar spot is common in South and Central America and in the Caribbean. A world map of distribution of the pathogen are available at <a target="_blank" href="https://www.cabi.org/isc/datasheet/40876#D0DD975C-959E-4666-930A-B0B80F7D233D">this website</a><sup>2</sup>. The disease was first observed in United States in 2015. An interactive map showing historical and current occurrences of tar spot in United States is given <a target="_blank" href="https://corn.ipmpipe.org/tarspot">here</a><sup>3</sup>. Prolonged (more than 7 hours) leaf wetness due to rain/fog or high relative humidity (greater than 75%) favors disease development.
    </FactSheetSection>

    <FactSheetSection name="Diagnosis">
      Pustules made by the tar spot fungi cannot be scraped off by fingernails, this feature helps to distinguish between tar spot and common/southern rust spores during late growing season<sup>4</sup>. Details on proper diagnosis for tar spot is given <a target="_blank" href="https://nt.ars-grin.gov/taxadescriptions/factsheets/index.cfm?thisapp=Phyllachoramaydis">here</a><sup>4</sup> and <a target="_blank" href="https://repository.cimmyt.org/handle/10883/775">here</a><sup>5</sup>.
    </FactSheetSection>

    <FactSheetSection name="Infectious agent genotype and phenotype information">
      DNA sequence of <i>P. maydis</i> is not available yet.
    </FactSheetSection>

    <FactSheetSection name="Infection or life cycle of the pathogen">
      Ascospores of <i>P. maydis</i> are produced in perithecia within stromata, exuded in a gelatinous matrix, and are likely dispersed by wind and rain. After infection and an incubation period of 12-20 days, new stromata are produced. New ascospores are produced within these stromata thereafter. The fungus can survive as stromata on residue for at least one winter in the weather conditions present in United States<sup>6</sup>.
    </FactSheetSection>

    <FactSheetSection name="Treatment and control">
      Residue management, rotation, and avoiding susceptible hybrids may reduce tar spot development and severity. Fungicide application may also reduce the infection rate. Currently there are no resistant corn varieties in commercial cultivation in the USA.
    </FactSheetSection>

    <FactSheetSection name="Notes">
      Severe tar spot can result in up to 30% (30 bushels per acre) reduction in corn yields. Both kernel development and maturity can be affected. The presence of <i>P. maydis</i> can also favor development of other diseases such as stalk rot resulting in increased economic burden.
    </FactSheetSection>

    <FactSheetSection name="Sources">
      <ol>
        <li>
          Tar Spot of Corn <a target="_blank" href="https://cropprotectionnetwork.org/resources/articles/diseases/tar-spot-of-corn">https://cropprotectionnetwork.org/resources/articles/diseases/tar-spot-of-corn</a> (accessed 2021-11-08).
        </li>
        <li>
          CABI.org. <i>Datasheet on Phyllochora Maydis (Black Spot of Maize)</i>. <a target="_blank" href="https://www.cabi.org/isc/datasheet/40876#toDistributionMaps">https://www.cabi.org/isc/datasheet/40876#toDistributionMaps</a> (accessed 2021-11-08)
        </li>
        <li>
          Corn ipmPIPE. Tar Spot - Corn IpmPIPE. <a target="_blank" href="https://corn.ipmpipe.org/">https://corn.ipmpipe.org/</a> (accessed 2021-11-08)
        </li>
        <li>
          Diagnostic Fact Sheet for Phyllachora maydis <a target="_blank" href="https://nt.ars-grin.gov/taxadescriptions/factsheets/index.cfm?thisapp=Phyllachoramaydis">https://nt.ars-grin.gov/taxadescriptions/factsheets/index.cfm?thisapp=Phyllachoramaydis</a> (accessed 2021-11-08).
        </li>
        <li>
          <i>Maize Diseases: A Guide for Field Identification</i>; CIMMYT, 2004. <a target="_blank" href="https://repository.cimmyt.org/xmlui/handle/10883/775">https://repository.cimmyt.org/xmlui/handle/10883/775</a> (accessed 2021-11-08)
        </li>
        <li>
          Kleczewski, N. M.; Plewa, D. E.; Bissonnette, K. M.; Bowman, N. D.; Byrne, J. M.; LaForest, J.; Dalla-Lana, F.; Malvick, D. K.; Mueller, D. S.; Chilvers, M. I.; Paul, P. A.; Raid, R. N.; Robertson, A. E.; Ruhl, G. E.; Smith, D. L.; Telenko, D. E. P. Documenting the Establishment, Spread, and Severity of Phyllachora Maydis on Corn, in the United States. <i>J. Integr. Pest Manag.</i> 2020, 11 (1), 14. <a target="_blank" href="https://doi.org/10.1093/jipm/pmaa012">https://doi.org/10.1093/jipm/pmaa012</a>.
        </li>
      </ol>
    </FactSheetSection>
  </FactSheet>
}
