
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { DATA_COLLECTION_STAGES } from "../redux/utils";
import LoadingSpinner from "../utils/LoadingSpinner";
import { runSearch } from "../redux/diseases/Actions";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Popover from 'react-bootstrap/Popover'


/**
 * Defines how to render a single field.
 */
class SearchField extends Component {
  render() {
    const {
      values,
      label,
      id,
      ...selectProps
    } = this.props;

    let dataSourceDiv;
    let dataContent;

    if (this.props.data_sources.length !== 0){

        dataSourceDiv = <div>
           <OverlayTrigger trigger="focus" placement="right" overlay={
             <Popover id="popover-basic">
               <Popover.Title as="h3">Useful sources for this data</Popover.Title>
               <Popover.Content>
                 <ul>
                   {this.props.data_sources.map((data_source)=>{
                     return <li key={ data_source.url }><a href={data_source.url} target="_blank" key={ data_source.url }>{data_source.title}</a></li>
                   })}
                 </ul>
               </Popover.Content>
             </Popover>}>
             <a href="#" variant="success">Data sources</a>
           </OverlayTrigger>
        </div>
    }

    const propertyLabel = <OverlayTrigger
      delay={{ show: 350, hide: 400 }}
      overlay={
        <Tooltip>
           { this.props.help_text }
         </Tooltip>
       }>
      <label className="text-muted" htmlFor={ id } >{ label }</label>
    </OverlayTrigger>

    if(values["type"] === "choice") {
      // Some values in the database have acronyms that need to be expanded for
      // the sake of clarity for the user. Here, we define the mapping between values
      // in the database and the value that should be displayed.
      const specialPropertyDDisplayTextMapping = {
        "Prominent disease prevalence/incidence": {
          "TR": "TR (<1%)",
          "LT": "LT (1-20%)",
          "MD": "MD (20-80%)",
          "HV": "HV (>80%)",
        },
        "Prominent severity of disease affected": {
          "TR": "TR (<1%)",
          "LT": "LT (1-20%)",
          "MD": "MD (20-80%)",
          "HV": "HV (>80%)",
        },
      }

      const options = [
        <option key={-1} value="">---</option>,
        ...values["options"].map(
          (currVal, i) => (
            <option key={ i } value={ currVal }>
              { label in specialPropertyDDisplayTextMapping && currVal in specialPropertyDDisplayTextMapping[label] ? specialPropertyDDisplayTextMapping[label][currVal] : currVal }
            </option>
          )
        )
      ]
  
      return (
        <div className="form-group">
          { propertyLabel }
          { dataSourceDiv }
          <select className="form-control" id={ id } {...selectProps}>
            { options }
          </select>
        </div>
      );
    } else if(values["type"] === "percentage") {
      return (
        <div className="form-group">
          { propertyLabel }
          { dataSourceDiv }
          <input type="number" className="form-control" id={ id } {...selectProps}/>
        </div>
      );
    }
  }
}

/**
 * Defines how we render an integer picker field.
 */
 class IntegerSearchField extends SearchField {
  render() {
    const {
      label,
      id,
      ...selectProps
    } = this.props;
  
    return (
      <div className="form-group">
        <label className="text-muted" htmlFor={ id } >{ label }</label>
        <input type="number" className="form-control" id={ id } {...selectProps}/>
      </div>
    );
  }
}


/**
 * Defines how we render our data picker fields.
 */
class DateSearchField extends SearchField {
  render() {
    const {
      label,
      id,
      ...selectProps
    } = this.props;
  
    return (
      <div className="form-group">
        <label className="text-muted" htmlFor={ id } >{ label }</label>
        <input type="date" className="form-control" id={ id } {...selectProps}/>
      </div>
    );
  }
}


/**
 * Defines the display for the properties that the user has selected.
 */
class SearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {fields: null};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(this.props.diseaseRequestState === DATA_COLLECTION_STAGES.collected) {
      this.initializeFormData();
    }
  }

  componentDidUpdate() {
    if(this.state.fields === null && this.props.diseaseRequestState === DATA_COLLECTION_STAGES.collected) {
      this.initializeFormData();
    }
  }

  initializeFormData() {
    const state = this.constructInitialState();
    this.setState(state);

    // Update the redux data for this form
    this.props.runSearch(state.fields);
  }

  /**
   * Determine the initial values that should be given to form fields.
   */
  constructInitialState = () => {
    // Get the list of values that are explictely set on the URL bar.
    const urlParams = new URLSearchParams(this.props.location.search);

    // Generate the search fields that should be displayed
    const fields = {};
    for(let propertyId of Object.keys(this.props.propertyDefinitions)) {
      let propertyDefn = this.props.propertyDefinitions[propertyId];
      if(propertyDefn.ptype !== "property") {
        // This field isn't intended for searching. Skip it.
        continue;
      }

      // Generate a field for this key of this property.
      for(let keyDefn of Object.values(propertyDefn.keys)) {
        fields[propertyDefn.prop.ref_id] = urlParams.has(propertyDefn.prop.ref_id) ? urlParams.get(propertyDefn.prop.ref_id) : "";
      }
    }

    // Check for user's point estimate fields
    fields["occurrence_count"] = urlParams.has("occurrence_count") ? urlParams.get("occurrence_count") : "";
    fields["start_date"] = urlParams.has("start_date") ? urlParams.get("start_date") : "";
    fields["end_date"] = urlParams.has("end_date") ? urlParams.get("end_date") : "";

    return {
      fields
    };
  }

  render() {
    // Will either hold a loading spinner (while we're looking up the details of
    // the selected disease) or a list of fields that should be displayed.
    let fields = undefined;

    if(this.state.fields === null || this.props.diseaseRequestState !== DATA_COLLECTION_STAGES.collected) {
      fields = <LoadingSpinner/>;
    } else {
      // Create a sorted array of sub-arrays. This array is responsible for defining
      // the order properties should be displayed in the search sidebar. Each element
      // of the outer list represents a single property. Each of these items is made
      // up of a 2-element sublist where the first element contains the unique identifier
      // for the property and the second element contains the key that shoulld be used
      // for sorting.
      //
      // Then do the sorting.
      //
      // Then, pull the IDs out of each of the sub-arrays so that we have an array
      // of property IDs in the order they should be displayed.
      let items = Object.keys(
        this.props.propertyDefinitions
      ).map(
        (key) => [key, this.props.propertyDefinitions[key].prop.name]
      ).sort(
        (pair1, pair2) => pair1[1].localeCompare(pair2[1])
      ).map(
        (pair) => pair[0]
      );

      // Generate the search fields that should be displayed
      fields = [];
      for(let propertyId of items) {
        let propertyDefn = this.props.propertyDefinitions[propertyId];
        if(propertyDefn.ptype !== "property") {
          // This field isn't intended for searching. Skip it.
          continue;
        }

        if(propertyDefn.prop.ref_id === "duration") {
          fields.unshift(
            <DateSearchField
              key="end_date"
              id="end_date"
              value={ this.state.fields["end_date"] }
              label="Date of last occurrence"
              onChange={ (event) => this.handleChange("end_date", event) }
            />
          )

          fields.unshift(
            <DateSearchField
              key="start_date"
              id="start_date"
              value={ this.state.fields["start_date"] }
              label="Date of first occurrence"
              onChange={ (event) => this.handleChange("start_date", event) }
            />
          )
          continue;
        }

        if(propertyDefn.prop.ref_id === "occurrence count") {
          fields.unshift(
            <IntegerSearchField
              key="occurrence_count"
              id="occurrence_count"
              value={ this.state.fields["occurrence_count"] }
              label={ propertyDefn.prop.name }
              onChange={ (event) => this.handleChange("occurrence_count", event) }
            />
          )
          continue
        }

        // Generate a field for this key of this property.
        for(let keyDefn of Object.values(propertyDefn.keys)) {
          // Get the list of legal values for this field.
          let values = propertyDefn.options[keyDefn.id.toString()];
          let help_text = propertyDefn.keys[0].help_text
          let data_sources = propertyDefn.keys[0].data_sources

          fields.push(
            <SearchField
              key={ propertyDefn.prop.name }
              id={ propertyDefn.prop.ref_id }
              values={ values }
              value={ this.state.fields[propertyDefn.prop.ref_id] }
              label={ propertyDefn.prop.name }
              help_text={ help_text }
              data_sources={ data_sources }
              onChange={ (event) => this.handleChange(propertyDefn.prop.ref_id, event) }
            />
          )
        }
      }
    }

    return (
      <div className="flex-column overflow-auto" style={{ "height": "80vh" }}>
        <p>
          <button
            class="btn btn-sm btn-outline-secondary"
            type="button"
            data-toggle="collapse"
            data-target="#search-options-collapse"
            aria-expanded="false"
            aria-controls="collapseExample"
            style={{ "width": "100%" }}
          >
            Search Options
          </button>
        </p>
        <form onSubmit={ this.handleSubmit }>
          <div
            class={this.props.searchOptionsWereSelected ? "collapse mb-3 show" : "collapse mb-3"}
            id="search-options-collapse"
          >
            <div class="card card-body">
              <ul className="nav mb-2">
                <li className="nav-item col-lg-12 p-0">
                  
                  { fields }

                </li>
              </ul>
            </div>
          </div>

          <button type="submit" value="Submit" className="btn btn-primary mb-5" style={{ width: "100%" }}>
            Search
          </button>
        </form>
      </div>
    )
  }

  /**
   * Handle submission of the search form.
   */
  handleSubmit(event) {
    // Tell the browser to not automatically submit the form to the backend. We'll
    // do that with our own code.
    event.preventDefault();

    // Convert the form's internal state to URL-safe GET parameters
    // so that we can set them in the user's URL bar.
    var params = new URLSearchParams({diseaseId: this.props.selectedDisease, ...this.state.fields}).toString();

    // Update the redux data for this form
    this.props.runSearch(this.state.fields);

    // Route the user to the page that displays
    // the outbreak records.
    this.props.history.push({pathname: "/results", search: params});
  }

  /**
   * Update the form's state when the user changes a field.
   */
  handleChange(propertyId, event) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [propertyId]: event.target.value
      }
    });
  }
}


function mapStateToProps(state) {
  return {
    propertyDefinitions: state.diseasesReducer.propertyDefinitions,
    diseaseRequestState: state.diseasesReducer.requestState,
    selectedDisease: state.diseasesReducer.selectedDisease,
    searchOptionsWereSelected: state.diseasesReducer.searchOptionsWereSelected
  };
};

export default connect(
  mapStateToProps,
  { runSearch }
)(withRouter(SearchForm));
