
import { Component } from "react";

import Plot from "../utils/Plotly";


/**
 * This plot displays the data for the composite time series data that
 * contains the total occurrences counts for every series in a record.
 */
export default class CompositeOccurrencesTimeSeries extends Component {
  render() {
    const {
      series,
      pointEstimate,
      ...plotlyOpts
    } = this.props;

    const points = this.seriesToPoints(series);
    const dates = Object.keys(points);

    const values = [];
    const cumulativeValues = [];
    let lastCumulativePointValue = null;
    for(let date of dates) {
      let currPointValue = points[date].size;
      values.push(currPointValue);

      let newCumulativePointValue = currPointValue + (lastCumulativePointValue !== null ? lastCumulativePointValue : 0);
      cumulativeValues.push(newCumulativePointValue);

      lastCumulativePointValue = newCumulativePointValue;
    }
    let dtick = '';

    // If the values of this time series is 1 then only display 1 daily tick in the x axis.
    // This is done in order to prevent multiple repeating tick labels from appearing in the plot.
    if (values.length === 1) {
      dtick = 'D1';
    }

    return (
      <Plot
        data={[
          {
            name: "Occurrences",
            type: 'bar',
            x: dates,
            y: values,
          },
          {
            name: "Cumulative Occurrences",
            type: 'line',
            x: dates,
            y: cumulativeValues,
            yaxis: 'y2',
            mode: 'lines+markers',
          },
          {
            x: [new Date(pointEstimate["duration"])],
            y: [pointEstimate["occurrence count"]],
            yaxis: 'y2',
            hoverinfo: 'text',
            hoverlabel: {
              bgcolor: 'rgba(192, 192, 192, 0.15)',
              bordercolor: 'rgba(200, 130, 250, 0.65)',
              font: {
                color: '#444',
                family: "verdana, arial, sans-serif",
                size: 12
              }
            },
            name: 'Point estimate (you are here)',
            mode: 'markers',
            marker: {
              color: 'rgba(200, 130, 250, 0.65)',
              size: 20
            },
            color: '#953579',
          }
        ]}
        layout={ {
          autosize: true,
          height: 400,
          yaxis: {
            tickformat: ',d',
            title: {
              text: 'Count',
              font: {
                color: '#7f7f7f'
              }
            }
          },
          yaxis2: {
            tickformat: ',d',
            title: {
              text: 'Cumulative Count',
              font: {
                color: '#7f7f7f'
              }
            }
          },
          yaxis2: {
            title: {
              text: 'Cumulative Count',
              font: {color: 'rgb(148, 103, 189)'},
            },
            rangemode: 'tozero',
            tickformat: '.2s',
            showgrid: false,
            overlaying: 'y',
            side: 'right'
          },
          xaxis: {
            title: {
              text: 'Date',
              font: {
                color: '#7f7f7f'
              },
              tickformat: '%B %e, %Y',
              dtick: dtick
            }
          },
          legend: {
              xanchor:"center",
              yanchor:"top",
              y: -.32,
              x:.5,
              orientation: "h"
          },
          ...plotlyOpts
        } }
        // Thanks [here](https://codepen.io/nicolaskruchten/pen/ERgBZX) for the
        // responsiv plot recipe.
        useResizeHandler={true}
        style={{width: "100%", height: "100%"}}
      />
    );
  }

  /**
   * Converts the given series data to a dictionary
   * that maps a particular date to the nubmer of occurrences on
   * that date.
   * 
   * `series` is the time series data that should be displayed,
   *    as returned by the API.
   */
  seriesToPoints(series) {
    const points = {};

    for(let point of series) {
      points[point.date] = point;
    }

    return points;
  }
}
