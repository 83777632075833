
import axios from 'axios';

import { toggleSeries } from "../series/Actions";


const ACTION_NS = "disease_records";
export const REQUEST_DATA = `${ACTION_NS}/get_records`;
export const SET_RECORDS = `${ACTION_NS}/set_records`;
export const REQUEST_RECORD_DETAILS = `${ACTION_NS}/get_record_details`;
export const SET_RECORD_DETAILS = `${ACTION_NS}/set_record_details`;
export const SET_PAGE = `${ACTION_NS}/set_page`;
export const CLEAR_RECORDS = `${ACTION_NS}/clear_records`;


export const scoringAlgorithmStatus = {
  "NOT_REQUESTED": "Scoring algorithm is not running",
  "RUNNING": "Scoring algorithm is currently running",
  "COMPLETE": "Scoring algorithm has finished",
  "BROWSE_ONLY": "No search will be performed"
}


/**
 * Saves the list of records for the selected disease to the store.
 * 
 * `scoringStatusRequestStage` is the stage of scoring that we're requesting.
 *    Specifically, if set to `scoringAlgorithmStatus.RUNNING`, any scoring results
 *    provided in the results will be disregarded as these results represent
 *    just the initial list of disease record results before any scoring results
 *    have been received. If it's `scoringAlgorithmStatus.COMPLETE`, then the
 *    scoring results from this request will be displayed.
 */
export function announceDiseaseRecords(records, scoringStatusRequestStage) {
  // Normalize the disease records
  // Index wrt analyst ID.
  const normRecords = records.results.reduce(
    (map, currRecord) => {
      map[currRecord.record.analyst_id] = currRecord.record;
      map[currRecord.record.analyst_id].score = currRecord.score;
      // Normalize the scoring results. Index wrt
      // property ID
      map[currRecord.record.analyst_id].scoreBreakdown = currRecord.scores.reduce(
        (scoreBreakdownMap, currScoreBreakdown) => {
          scoreBreakdownMap[currScoreBreakdown.property_id] = currScoreBreakdown;

          return scoreBreakdownMap;
        },
        {}
      );
      return map;
    },
    {}
  )

  return {
    type: SET_RECORDS,
    payload: normRecords,
    numRecords: records.count,
    scoringStatusRequestStage: scoringStatusRequestStage
  }
}


/**
 * Initiates the API requests needed in order to collect the data for this
 * disease.
 * 
 * `form` is a dictionary containing the form values that should be used when
 *    describing the user's request to the back end.
 */
export function requestDiseaseRecords(form=null) {
  return async (dispatch, getState) => {
    dispatch({type: REQUEST_DATA});

    // First, we request the disease records _without_ specifying the
    // scores that shoule be used. This allows the records to be displayed
    // withour running the full scoring algorithm.
    let currState = getState();
    let records = await axios.get(
      "/api/results",
      {
        params: {
          limit: currState.diseaseRecordsReducer.page.recordsPerPage,
          offset: currState.diseaseRecordsReducer.page.recordsPerPage * (currState.diseaseRecordsReducer.page.currPage - 1),
          disease: currState.diseasesReducer.selectedDisease,
        }
      }
    );

    const userHasNotSelectedSearchOptions = !currState.diseasesReducer.searchOptionsWereSelected;
    if(userHasNotSelectedSearchOptions) {
      dispatch(announceDiseaseRecords(records.data, scoringAlgorithmStatus.BROWSE_ONLY));
      // If the user doesn't want to search for anything, skip the scoring process.
      return;
    }
    
    dispatch(announceDiseaseRecords(records.data, scoringAlgorithmStatus.RUNNING));

    // Now, run the request with the user's input so we get the actual scores.
    currState = getState();
    records = await axios.get(
      "/api/results",
      {
        params: {
          limit: currState.diseaseRecordsReducer.page.recordsPerPage,
          offset: currState.diseaseRecordsReducer.page.recordsPerPage * (currState.diseaseRecordsReducer.page.currPage - 1),
          disease: currState.diseasesReducer.selectedDisease,
          ...form,
        }
      }
    );
    dispatch(announceDiseaseRecords(records.data, scoringAlgorithmStatus.COMPLETE));
  }
}


/**
 * Set the page of results we should view.
 */
export function setPage(pageNum) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PAGE, pageNum });

    const currState = getState();
    await requestDiseaseRecords(
      currState.diseasesReducer.form
    )(dispatch, getState);
  };
}


/**
 * Collects the detailed data for a specific disease record.
 * 
 * `recordId` is the ID of the disease that should be collected.
 */
export function requestDiseaseRecordDetails(recordId) {
  return async (dispatch, getState) => {
    dispatch({type: REQUEST_RECORD_DETAILS, recordId});

    return axios.get(`/api/records/${recordId}/`)
      .then((recordDetailsResult) => {
        dispatch({type: SET_RECORD_DETAILS, payload: recordDetailsResult.data, recordId});

        return recordDetailsResult.data;
      })
      .then(async (recordDetailsResult) => {
        // When every disease record is first loaded, enable the very first series so
        // that it will be displayed by default.
        if(recordDetailsResult.series.length > 0) {
          await toggleSeries(
            recordDetailsResult.id,
            recordDetailsResult.series[0]
          )(dispatch, getState)
        }
      })
  }
}


/**
 * Drops all records and records from the current set of results.
 * This allows you to re-query data if the user updates the form.
 */
export function clearRecords() {
  return {
    type: CLEAR_RECORDS
  }
}
