
import { Component } from "react";

import Plot from "../utils/Plotly";



/**
 * Defines how to render a single value for a disease record's property.
 */
export class OccurrencesTimeSeries extends Component {
  render() {
    const {
      series,
      ...plotlyOpts
    } = this.props;

    const points = this.seriesToPoints(series);
    const dates = Object.keys(points);

    const values = dates.map((date) => points[date].count);
    let dtick = '';

    // If the values of this time series is 1 then only display 1 daily tick in the x axis.
    // This is done in order to prevent multiple repeating tick labels from appearing in the plot.
    if (values.length === 1) {
      dtick = 'D1';
    }

    return (
      <Plot
        data={[
          {
            type: 'bar',
            x: dates,
            y: values,
            marker: {
              color: series.color
            }
          },
        ]}
        layout={ {
          autosize: true,
          height: 400,
          yaxis: {
            tickformat: ',d',
            title: {
              text: 'Count',
              font: {
                color: '#7f7f7f'
              }
            }
          },
          xaxis: {
            title: {
              text: 'Date',
              font: {
                color: '#7f7f7f'
              }
            },
            tickformat: '%B %e, %Y',
            dtick: dtick
          },
          ...plotlyOpts
        } }
        // Thanks [here](https://codepen.io/nicolaskruchten/pen/ERgBZX) for the
        // responsiv plot recipe.
        useResizeHandler={true}
        style={{width: "100%", height: "100%"}}
      />
    );
  }

  /**
   * Converts the given series data to a dictionary
   * that maps a particular date to the nubmer of occurrences on
   * that date.
   * 
   * `series` is the time series data that should be displayed,
   *    as returned by the API.
   */
  seriesToPoints(series) {
    // My kingdom for DataFrame.groupby()
    let points = {};
    for(let curTimeStep of series.data) {
      let curDate = curTimeStep.date.date;
      if(!(curDate in points)) {
        // This is the first time we've seen this date. Initialize it.
        points[curDate] = {
          count: 0
        };
      }

      points[curDate].count++;
    }

    return points;
  }
}
