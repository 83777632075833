
import { Component } from "react";
import { connect } from "react-redux";

import { DATA_COLLECTION_STAGES }  from "../redux/utils";
import DiseaseRecord from "./Record";
import LoadingSpinner from "../utils/LoadingSpinner";


/**
 * Defines how to display a list of disease records.
 */
class RecordsList extends Component {
  render() {
    if(this.props.requestState === DATA_COLLECTION_STAGES.noRequest) {
      return this.renderNoData();
    } else if(this.props.requestState === DATA_COLLECTION_STAGES.collecting) {
      return this.renderCollectingData();
    } else if(this.props.requestState === DATA_COLLECTION_STAGES.collected) {
      return this.renderData();
    }
  }

  /**
   * Defines the user interface that should be displayed if there is no data
   * and the data collection process has not yet started.
   */
  renderNoData() {
    return null;
  }

  /**
   * Defines the user interface that should be displayed if the data are
   * currently being requested.
   */
  renderCollectingData() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <LoadingSpinner/>
        </div>
      </div>
    )
  }

  /**
   * Defines the user interface that should be displayed once we've collected all the
   * data we need.
   */
  renderData() {
    const recordDisplays = Object.values(this.props.records)
      // Filter out any disease records that belong to a different disease.
      // We cache disease records between calls, so if you don't do this, you'll try to include records from other
      // diseases if the user visits multiple disease pages without refreshing.
      .filter((diseaseRecord) => this.props.selectedDisease === diseaseRecord.disease.ref_id)
      // Build the UI elements.
      .map(
        (diseaseRecord) => <DiseaseRecord
          key={ diseaseRecord.analyst_id }
          recordSummary={ diseaseRecord }
          recordDetails={ this.props.recordDetails[diseaseRecord.id] }
          series={ diseaseRecord.id in this.props.seriesByRecordVisibility
            ? this.props.seriesByRecordVisibility[diseaseRecord.id]
            : null
          }
          focusSeries={ diseaseRecord.id in this.props.seriesByRecordFocus
            ? this.props.seriesByRecordFocus[diseaseRecord.id]
            : null
          }
          totalOccurrencesSeries={ diseaseRecord.id in this.props.countOfOccurrencesInAllSeriesByRecord
            ? this.props.countOfOccurrencesInAllSeriesByRecord[diseaseRecord.id]
            : null
          }
        />
      );

    return (
      <div className="row">
        <nav data-spy="scroll" data-target="#disease-list-scrollspy" data-offset="0" className="col-lg-12">
          { recordDisplays }
        </nav>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    requestState: state.diseaseRecordsReducer.requestState,
    records: state.diseaseRecordsReducer.records,
    recordDetails: state.diseaseRecordsReducer.recordDetails,
    series: state.seriesReducer.series,
    seriesByRecordVisibility: state.seriesReducer.byRecordVisibility,
    diseaseForm: state.diseasesReducer.form,
    seriesByRecordFocus: state.seriesReducer.byRecordFocus,
    countOfOccurrencesInAllSeriesByRecord: state.compositeSeriesReducer.byRecordId.countOfOccurrencesInAllSeries,
    selectedDisease: state.diseasesReducer.selectedDisease
  };
};

export default connect(
  mapStateToProps
)(RecordsList);

