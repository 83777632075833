
import { DATA_COLLECTION_STAGES } from "../utils";
import {
  CREATE_SERIES,
  ANNOUNCE_SERIES,
  SET_VISIBLE,
  SET_INVISIBLE,
  SELECT_FOCUS_SERIES
} from "./Actions";
import { color, simpleStrHash } from "../../utils/ColorScheme";


var initialSeriesState = {
  // Indicates what data collection stage we're currently in.
  requestState: DATA_COLLECTION_STAGES.collecting,
  // The color that should be used for this series.
  color: "blue"
}


var initialState = {
  // Will be a mapping from series ID to the series object
  // containing the current data for that series (see `initialSeriesState`)
  series: {},
  // Will be a mapping from record ID to the series objects which are visible
  // for that record.
  byRecordVisibility: {},
  // Will be a mapping from record ID to the series object that is the current
  // focus series for that record.
  byRecordFocus: {}
}


function seriesReducer(state=initialState, action) {
  switch (action.type) {
    case CREATE_SERIES:
      return {
        ...state,
        series: {
          ...state.series,
          [action.id]: {
            ...initialSeriesState
          }
        }
      };
    case ANNOUNCE_SERIES:
      return {
        ...state,
        series: {
          ...state.series,
          [action.id]: {
            ...state.series[action.id],
            ...action.payload,
            requestState: DATA_COLLECTION_STAGES.collected,
            color: color(simpleStrHash(action.payload.type))
          }
        }
      };
    case SET_VISIBLE:
      return {
        ...state,
        byRecordVisibility: {
          ...state.byRecordVisibility,
          [action.recordId]: {
            ...state.byRecordVisibility[action.recordId],
            [action.id]: {
              ...state.series[action.id]
            },
          }
        }
      };
    case SET_INVISIBLE:
      // Drop the series from the list of visibles.
      // Doesn't yet update the state, we still need
      // to generate a new object.
      delete state.byRecordVisibility[action.recordId][action.id];

      return {
        ...state,
        byRecordVisibility: {
          ...state.byRecordVisibility,
          [action.recordId]: {
            ...state.byRecordVisibility[action.recordId]
          }
        }
      };
    case SELECT_FOCUS_SERIES:
      return {
        ...state,
        byRecordFocus: {
          ...state.byRecordFocus,
          [action.recordId]: {
            ...state.series[action.id]
          }
        }
      };
    default:
      return state;
  }
}

export default seriesReducer;
