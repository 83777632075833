
import axios from 'axios';

const ACTION_NS = "series";
export const CREATE_SERIES = `${ACTION_NS}/create_series`;
export const ANNOUNCE_SERIES = `${ACTION_NS}/series_received`;
export const SET_VISIBLE = `${ACTION_NS}/set_visible`;
export const SET_INVISIBLE = `${ACTION_NS}/set_invisible`;
export const SELECT_FOCUS_SERIES = `${ACTION_NS}/select_focus_series`;


/**
 * Allocates space for a new series. This adds the default, empty
 * series to the redux store. The series is given the specified ID.
 */
export function createSeries(seriesId) {
  return {
    type: CREATE_SERIES,
    id: seriesId
  }
}


/**
 * Initiates the API requests needed to get the data for a specific series.
 * 
 * `seriesSpec` is an object containing the ID and URL of the
 *    series that should be collected.
 */
export function getSeries(seriesSpec) {
  return async (dispatch, getState) => {
    dispatch(createSeries(seriesSpec.id));

    const seriesData = await axios.get(seriesSpec.url);
    dispatch({type: ANNOUNCE_SERIES, payload: seriesData.data, id: seriesSpec.id});
  }
}


/**
 * Toggle the given series to be visible/invisible
 * 
 * `recordId` is the record for which this series should be toggled.
 * `seriesSpec` is an object containing the ID and URL of the
 *    series that should be toggled.
 */
export function toggleSeries(recordId, seriesSpec) {
  return async (dispatch, getState) => {
    let currState = getState();

    // Check whether the data for this series have already been downloaded.
    if(!(seriesSpec.id in currState.seriesReducer.series)) {
      // Data haven't been downloaded. Start that request process.
      await getSeries(seriesSpec)(dispatch, getState);
      currState = getState();
    }

    // We have data for the series. Toggle its visibility setting.
    if(recordId in currState.seriesReducer.byRecordVisibility && seriesSpec.id in currState.seriesReducer.byRecordVisibility[recordId]) {
      // Series is visible. Hide it.
      dispatch({type: SET_INVISIBLE, id: seriesSpec.id, recordId})
    } else {
      // Series is invisible. Display it.
      dispatch({type: SET_VISIBLE, id: seriesSpec.id, recordId})
    }
  }
}


/**
 * Select a particular series as the focus series.
 */
export function selectFocusSeries(recordId, seriesSpec) {
  return async (dispatch, getState) => {
    let currState = getState();

    // Check whether the data for this series have already been downloaded.
    if(!(seriesSpec.id in currState.seriesReducer.series)) {
      // Data haven't been downloaded. Start that request process.
      await getSeries(seriesSpec)(dispatch, getState);
      currState = getState();
    }

    // We have data for the series. Set it as the focus series.
    dispatch({type: SELECT_FOCUS_SERIES, id: seriesSpec.id, recordId})
  }
}
