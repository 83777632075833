
import { Component } from "react";


/**
 * Defines how to render a single value for a disease record's property.
 */
class PropertyValue extends Component {
  render() {
    if(this.props.keyName === "") {
      return <span>{ this.props.value }</span>
    } else if (this.props.value && (this.props.value.startsWith('http') || this.props.value.startsWith('www.'))) {
      return <span>{ this.props.keyName }: <a href={ this.props.value } target="_blank">{ this.props.value }</a></span>
    } else {
      return <span>{ this.props.keyName }: { this.props.value }</span>
    }
  }
}


/**
 * Defines how to render an instance of a property definition.
 * 
 * Children can be defined. Recommend using the `PropertyValue` class to define
 *    the specific values that should be displayed for each key this property.
 */
 class PropertyInstance extends Component {
  render() {
    return (
      <ul>
        { this.props.children }
      </ul>
    )
  }
}


/**
 * Defines how a collection of values for the same valuue should be displayed.
 * 
 * Children can be defined. Recommend using the `PropertyInstance` class to define
 *    the specific values that should be displayed for this property.
 */
class PropertyValueCollection extends Component {
  render() {
    return (
      <div className="list-group-item flex-column align-items-start">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">{ this.props.propName }</h5>
        </div>

        { this.props.children }
      </div>
    )
  }
}


/**
 * Defines how to build a list of values that should be displayed in the features/properties listing.
 * 
 * `propertiesToRender` is a list of property values containing the properties that should be displayed
 *    in tihs value collection.
 */
export default function UnscoredPropertyView(props) {
  const {
    propertyDefinitions,
    propertiesToRender
  } = props;

  const propertyDisplays = propertiesToRender.map(
    (property, i) => (
      property.definition in propertyDefinitions ? (
        <PropertyValueCollection propName={ propertyDefinitions[property.definition].prop.name }>
          <PropertyInstance
            key={ i }
            propertyDefinition={ propertyDefinitions[property.definition] }
          >
            {
              property.values.map((currentValue, i) => (
                <li key={ i }>
                  <PropertyValue
                    propName={ propertyDefinitions[property.definition].prop.name }
                    keyName={ propertyDefinitions[property.definition].keys.filter((curKey) => curKey.id === currentValue.key)[0].name }
                    value={ currentValue.value }
                  />
                </li>
              ))
            }
          </PropertyInstance>
        </PropertyValueCollection>
      ) : null
    )
  );

  return propertyDisplays;
}