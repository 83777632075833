
import { FactSheet, FactSheetSection } from "./FactsSheet";
import { EddmapsSection, EddmapsIframe } from "../Eddmaps/Images";


/**
 * Displays the fact sheet for Corn Southern Rust.
 */
export default function CornSouthernRustFactSheet(props) {
  return <FactSheet name="Southern Corn Rust">
    <FactSheetSection name="Pathogen">
      Southern corn rust is caused by the fungus <i>Puccinia polysora</i><sup>1,2</sup>.
    </FactSheetSection>

    <EddmapsSection title="Southern Corn Rust Images">
      <div className="row">
        <div className="col-lg-6">
          <EddmapsIframe diseaseName="Southern Corn Rust" url="https://images.bugwood.org/gallery.cfm?coll=111005&color=transparent&notitle"/>
        </div>
      </div>  
    </EddmapsSection>

    <FactSheetSection name="Host and reservoir">
      The fungus is known to infect only corn, known alternate hosts are silver plume grass and eastern gammagrass<sup>3</sup>. 
    </FactSheetSection>

    <FactSheetSection name="Transmission">
      Wind-borne urediniospores are the primary mechanism of infection. In the United States this fungus does not survive the winter season, therefore all infective urediniospores are carried north from tropical regions<sup>3,4</sup>.
    </FactSheetSection>

    <FactSheetSection name="Disease symptoms" subtitle="Onset, duration and characteristic features">
      Symptoms of Southern corn rust are very similar to symptoms of common rust in corn (caused by <i>Puccinia sorghi</i>). Pustules produced by southern rust are smaller and occur almost exclusively on the upper leaf surface. Pustules are usually circular or oval, very numerous, and densely scattered over the leaf surface. Spores are orange when they erupt from the pustule. As pustules age, they become chocolate brown to black, often forming dark circles around the original pustule. Images of Southern rust are available at this <a target="_blank" href="https://cropprotectionnetwork.org/resources/articles/diseases/southern-rust-of-corn">website</a><sup>5</sup>.
    </FactSheetSection>

    <FactSheetSection name="Epidemiology and risk factors">
      Southern rust develops during warmer temperatures (80-90° F). The rust spores are blown in from South America and Southern Florida. Approximately six hours of leaf moisture (dew) favors disease development. When conditions are favorable new infections will continue to occur for 7-14 days. If the cycles continue, disease intensity could achieve epidemic levels. Interactive maps showing southern rust of corn are available <a target="_blank" href="https://corn.ipmpipe.org/southerncornrust/">here</a><sup>6</sup>.
    </FactSheetSection>

    <FactSheetSection name="Diagnosis">
      Distinguishing between common rust and southern rust based on symptoms alone is problematic. Southern rust pustules mainly appear only on the upper leaf surface (common rust pustules appear on both lower and upper leaf surface). Microscopic analyses can be used to differentiate between the spores of the two rusts. Common rust spores are round whereas southern rust spores are oval. Images of southern rust are available at this <a target="_blank" href="https://cropprotectionnetwork.org/resources/articles/diseases/southern-rust-of-corn">website</a><sup>5</sup>. Crouch and Szabo reported development of real-time PCR assay to discriminate between <i>P. polysora</i> and <i>P. sorghi</i> in their <a target="_blank" href="https://apsjournals.apsnet.org/doi/abs/10.1094/PDIS-10-10-0745">publication</a><sup>7</sup>.
    </FactSheetSection>

    <FactSheetSection name="Infectious agent genotype and phenotype information">
      Sequence based information is not currently available.
    </FactSheetSection>

    <FactSheetSection name="Infection or life cycle of the pathogen">
      Infectious urediniospores transported by wind storms land on corn plants under susceptible environmental conditions and infect plants. The orangish-red spores then infect nearby plants and fields, if the favorable environmental conditions persist. A diagram of the southern rust infectious life cycle is available <a target="_blank" href="https://cropprotectionnetwork.org/resources/articles/diseases/southern-rust-of-corn">here</a><sup>5</sup>.
    </FactSheetSection>

    <FactSheetSection name="Treatment and control">
      Earlier planting will reduce the susceptibility of plants to rust spores arriving from tropics. Planting rust resistant hybrid varieties is the most effective method. Fungicide application can be used if needed. Tillage and crop rotation are not effective as the spores are windborne.
    </FactSheetSection>

    <FactSheetSection name="Notes">
      In 2008, a new race of southern rust fungus was discovered in Georgia that caused disease in formerly resistant varieties of corn. Corn varieties resistant to southern rust may contain the <i>Rpp</i> gene that confers resistance to the fungi. However, the new strain (race or pathotype) discovered in Georgia can infect plants that has the <i>Rpp</i> gene<sup>8</sup>.
    </FactSheetSection>

    <FactSheetSection name="Sources">
      <ol>
        <li>
          Korus, K. <i>Southern vs. Common Rust in Corn</i>; IFAS Extention - fact sheet 2019002; University of Florida.
        </li>
        <li>
          Wise, K. <i>Diseases of Corn Common and Southern Rusts</i>; Extension report BP-82-W; Purdue University.
        </li>
        <li>
          Pereira J. <i>Puccinia polysora</i>. Bugwood wiki. <a target="_blank" href="https://wiki.bugwood.org/Puccina_polysora#Pathogen">https://wiki.bugwood.org/Puccina_polysora#Pathogen</a> Accessed April 11, 2022.
        </li>
        <li>
          North Carolina State University. Corn Rusts: Common and Southern Rust | NC State Extension Publications <a target="_blank" href="https://content.ces.ncsu.edu/corn-rusts-common-and-southern-rust">https://content.ces.ncsu.edu/corn-rusts-common-and-southern-rust</a> (accessed 2021-11-09).
        </li>
        <li>
          Southern Rust of Corn <a target="_blank" href="https://cropprotectionnetwork.org/resources/articles/diseases/southern-rust-of-corn">https://cropprotectionnetwork.org/resources/articles/diseases/southern-rust-of-corn</a> (accessed 2021-11-09).
        </li>
        <li>
          Webmaster. Southern Corn Rust - Corn IpmPIPE.
        </li>
        <li>
          Crouch JA and Szabo LJ (2011) Real-Time PCR Detection and Discrimination of the Southern and Common Corn Rust Pathogens <i>Puccinia polysora</i> and <i>Puccinia sorghi. APS Publications</i> <a target="_blank" href="https://doi.org/10.1094/PDIS-10-10-0745">https://doi.org/10.1094/PDIS-10-10-0745</a>
        </li>
        <li>
          Identifying and Managing Southern Rust of Corn <a target="_blank" href="https://www.specialtyhybrids.com/en-us/agronomy-library/southern-rust-of-corn.html">https://www.specialtyhybrids.com/en-us/agronomy-library/southern-rust-of-corn.html</a> (accessed 2022-1-13).
        </li>
      </ol>
    </FactSheetSection>
  </FactSheet>
}
