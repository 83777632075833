
import { Fragment, Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { connect } from "react-redux";

import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import RecordsList from "../DiseaseRecords/RecordsList";
import SearchForm from "../SearchForm/SearchForm";
import DiseaseForm from "../SearchForm/DiseaseForm";
import DiseaseRecordPaginator from "../DiseaseRecords/Paginator";
import { getDiseases, selectDisease } from "../redux/diseases/Actions";
import { scoringAlgorithmStatus } from "../redux/diseaseRecords/Actions";
import { SmallLoadingSpinner } from "../utils/LoadingSpinner";
import WheatRustNationalFactSheet from "./WheatRustNationalFacts";
import WheatRustKansasFactSheet from "./WheatRustKansasFacts";
import WheatBlotchFactSheet from "./WheatBlotchFacts";
import WheatBlotchNationalFactSheet from "./WheatBlotchNationalFacts";
import CornTarSpotFactSheet from "./CornTarSpot";
import CornSouthernRustFactSheet from "./CornSouthernRust";
import aidoLogo from "../images/aido_logo.png";
import imgAidoExampleResults from "../images/aido4crops-example-results.png";
import imgAidoExampleFactSheet from "../images/aido4crops-fact-sheet-example.png";
import TutorialPage from "./Tutorial";


/**s
 * Maps the ID of a disease to the fact sheet that should be used to
 * render that disease.
 */
const DISEASE_ID_TO_FACT_SHEET = {
  "wheat_rust": <WheatRustNationalFactSheet/>,
  "wheat_rust_ks": <WheatRustKansasFactSheet/>,
  "wheat_blotch": <WheatBlotchFactSheet/>,
  "corn tar spot": <CornTarSpotFactSheet/>,
  "corn Southern rust": <CornSouthernRustFactSheet/>,
  "wheat blotch national": <WheatBlotchNationalFactSheet/>,
}


/**
 * Main entrypoint for drawing the app.
 * 
 * Original bootstrap template example taken from: https://getbootstrap.com/docs/4.0/examples/sticky-footer-navbar/
 */
function App() {
  return (
    <Router>
      <Header/>

      <div className="container-fluid" style={{ marginTop: "75px" }}>
        <Switch>
          <Route path="/results">
            <DiseaseDataPage displayResults={ true }/>
          </Route>
          <Route path="/search">
            <DiseaseDataPage displayResults={ false }/>
          </Route>
          <Route path="/tutorial">
            <TutorialPage/>
          </Route>
          <Route path="/">
            <LandingPage/>
          </Route>
        </Switch>
      </div>

      <Footer/>
    </Router>
  );
}


/**
 * Displays the main AIDO landing page.
 */
class _LandingPage extends Component {
  componentDidMount() {
    this.props.getDiseases();
  }
  
  render() {
    return (
      <div className="row">
        <main className="col-md-12 ml-sm-auto col-lg-12 px-4 mt-3" role="main" style={{ position: "static" }}>
          <div className="jumbotron">

            <div className="row">
              <div className="col-lg-4">
                <img className="mx-auto d-block" src={ aidoLogo } style={{ maxWidth: "100%", maxHeight: "300px"}}/>
              </div>

              <div className="col-lg-8">
                <h1 className="display-4">AIDO4Crops</h1>
                <p className="lead">
                  AIDO is a decision-support tool designed to enhance situational awareness
                  during unfolding disease outbreaks by providing the user with detailed
                  background information on historical outbreaks. 
                </p>

                <p>AIDO4Crops is a redesign of the original AIDO tool geared toward plant diseases.</p>

                <hr className="my-4"/>

                <DiseaseForm/>
              </div>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-lg-1"/> */}
            <div className="col-lg-6">
              <div id='intro-section-1' className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">
                    About
                  </h4>
                </div>

                <div className="card-body">
                  <p>The goal of this tool is to offer the user a combination of historical data with specific analytics to provide:</p>
                  <ol>
                    <li>A basic estimate of the trajectory of the user's ongoing situation through comparison to similar historical events, and</li>
                    <li>To offer actionable information on what mitigation actions the user could plan or recommend, to control their ongoing situation.</li>
                  </ol>
                  <p>
                    This tool is currently under development
                    and the historic library in this tool will be limited to state-based data for United
                    States currently. We are still building this library and further improvements to the
                    website will also be implemented soon.
                  </p>

                  <hr/>
                  <img className="mx-auto d-block" src={ imgAidoExampleFactSheet } style={{ maxWidth: "100%" }}/>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div id='intro-section-1' className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">
                    Usage
                  </h4>
                </div>

                <div className="card-body">
                  <p>
                    AIDO4Crops uses a similarity algorithm to find the closest matching disease specific historical
                    occurrence to ongoing situations in the field. The analytic asks for a series of inputs
                    (a set of fillable questions) to collect data for on-going field conditions. The same information
                    has been collected for the disease specific historical occurrence library that is used for
                    comparison. Input fields have been determined based on an analysis of what “properties”
                    influence the magnitude and/or duration of a disease occurrence.  The similarity algorithm uses
                    these values to identify the most similar historical occurrence and provides extensive data on this
                    occurrence. The data presented in various tabs of AIDO4Crops include a geographical map of
                    occurrence, a time series of occurrence (date and count) with “you are here”, details on the
                    crop condition in the historical year as a way of providing information to the user on possible
                    progress during current crop season. AIDO4Crops also provides details of the score used to calculate
                    the similarity algorithm as well as original data sources for the information on the features tab.
                  </p>

                  <hr/>
                  <img className="mx-auto d-block" src={ imgAidoExampleResults } style={{ maxWidth: "100%" }}/>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-1"/> */}
          </div>
        </main>
      </div>
    );
  }
}

function LandingPageMapStateToProps(state) {
  return {
    selectedDisease: state.diseasesReducer.selectedDisease,
  };
};

const LandingPage = connect(
  LandingPageMapStateToProps,
  { getDiseases }
)(_LandingPage);


/**
 * This component determines, based on the current state of the app, which 
 * components should be used to render the disease data.
 */
class DiseaseDataPage extends Component {
  render() {
    let diseaseDetailsSection = null;
    if(this.props.displayResults) {
      diseaseDetailsSection = <DiseaseRecordView/>
    } else {
      diseaseDetailsSection = <DiseaseOverview/>
    }

    return (
      <Fragment>
        <Header/>
  
        <div style={{ marginTop: "58px" }}>
          <div className="row">
            <nav id="navbarCollapse" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" style={{ position: "fixed", height: "100%"}}>
              <div className="sidebar-sticky position-sticky">
                <div className="mt-2">
                  <DiseaseForm/>
                  <hr/>
                  <SearchForm/>
                </div>
              </div>
            </nav>

            <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
              { diseaseDetailsSection }
            </main>
          </div>
        </div>
  
        <Footer/>
      </Fragment>
    );
  }
}


/**
 * Displays the overview page for a particular disease.
 */
class _DiseaseOverview extends Component {
  render() {
    if(this.props.diseases === null) {
      // We do not have information about the disease list yet. Wait for data.
      return null;
    }

    return DISEASE_ID_TO_FACT_SHEET[this.props.selectedDisease]
  }
}

function DiseaseOverviewMapStateToProps(state) {
  return {
    selectedDisease: state.diseasesReducer.selectedDisease,
    diseases: state.diseasesReducer.diseases,
  };
};

const DiseaseOverview = connect(
  DiseaseOverviewMapStateToProps,
  { getDiseases, selectDisease }
)(_DiseaseOverview);


/**
 * Displays the summary data for a record in the record list.
 */
function DiseaseRecordListElement(props) {
  const browseOnly = props.scoringResultsStatus === scoringAlgorithmStatus.BROWSE_ONLY
  const weightsAreCalculated = props.scoringResultsStatus === scoringAlgorithmStatus.COMPLETE;

  let scoringPercentage = null;
  if(!browseOnly && weightsAreCalculated) {
    scoringPercentage = <small className="text-muted">{ (props.recordSummary.score * 100).toFixed(2) }%</small>
  }

  return (
    <li className="nav-item list-group-item pl-0 py-1">
      <a className="nav-link" href={ `#disease-record-${props.recordSummary.id}` } style={{ textOverflow: "ellipsis", fontSize: "15px"}}>
        { scoringPercentage } { props.recordSummary.name }
      </a>
    </li>
  )
}


/**
 * Displays the disease record view page.
 */
class _DiseaseRecordView extends Component {
  render() {
    const recordsAreLoaded = this.props.records !== null;
    const browseOnly = this.props.scoringResultsStatus === scoringAlgorithmStatus.BROWSE_ONLY
    const weightsAreCalculated = this.props.scoringResultsStatus === scoringAlgorithmStatus.COMPLETE;

    let recordSummaryElements = [];
    if(recordsAreLoaded) {
      recordSummaryElements = Object.values(this.props.records).map(
        (recordSummaryData) => <DiseaseRecordListElement
          scoringResultsStatus={ this.props.scoringResultsStatus }
          key={ recordSummaryData.id }
          recordSummary={ recordSummaryData }
        />
      );
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-9 col-lg-10 mt-3 pl-4">
            <DiseaseRecordPaginator/>
          </div>
        </div>

        <div className="row">
          <main role="main" className="col-lg-9 px-4 mt-3">
            <RecordsList/>
          </main>

          <nav className="col-lg-1 d-md-block sidebar">
            <div className="row" style={{ position: "fixed", height: "100%" }}>
              <div className="col-lg-12 sidebar-sticky position-sticky pt-3">

                {
                  !browseOnly && !weightsAreCalculated && recordsAreLoaded
                  ? <small className="text-muted"><SmallLoadingSpinner/> Search scores are loading</small>
                  : null
                }

                <ul id="disease-list-scrollspy" className="nav list-group list-group-flush">
                  { recordSummaryElements }
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div className="row">
          <div className="col-md-9 col-lg-10 pl-4">
            <DiseaseRecordPaginator/>
          </div>
        </div>
      </Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    selectedDisease: state.diseasesReducer.selectedDisease,
    diseases: state.diseasesReducer.diseases,
    records: state.diseaseRecordsReducer.records,
    scoringResultsStatus: state.diseaseRecordsReducer.scoringStatus
  };
};

const DiseaseRecordView = connect(
  mapStateToProps,
  { getDiseases, selectDisease }
)(_DiseaseRecordView);


export default App;
