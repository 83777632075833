
import { DATA_COLLECTION_STAGES } from "../utils";


import {
  REQUEST_DATA,
  SET_RECORDS,
  REQUEST_RECORD_DETAILS,
  SET_RECORD_DETAILS,
  SET_PAGE,
  CLEAR_RECORDS,
  scoringAlgorithmStatus
} from './Actions';


/**
 * Helper method that simply generates the default page number
 * settings.
 */
function generateInitialPage() {
  return {
    numRecords: 0,
    recordsPerPage: 5,
    currPage: 1
  }
}


var initialState = {
  // Indicates what data collection stage we're currently in.
  requestState: DATA_COLLECTION_STAGES.noRequest,

  // Will hold the records that are in this disease.
  records: null,

  // Will hold the detailed information for any records that have been
  // fully loaded.
  recordDetails: {},

  // Will hold whether the scoring algorithm has been run (and therefore,
  // disease record scores should be displayed) or the scoring algorithm
  // is currently running but the algorithm has not yet finished (in which
  // case, loading wheels should be displayed) or whether the request has
  // not yet finished.
  scoringStatus: scoringAlgorithmStatus.NOT_REQUESTED,

  // Records the user's current location in the pages of results.
  page: generateInitialPage()
}


function diseaseRecordsReducer(state=initialState, action) {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        requestState: DATA_COLLECTION_STAGES.collecting,
        records: null,
        recordDetails: {}
      }
    case SET_RECORDS:
      return {
        ...state,
        records: action.payload,
        requestState: DATA_COLLECTION_STAGES.collected,
        scoringStatus: action.scoringStatusRequestStage,
        page: {
          ...state.page,
          numRecords: action.numRecords
        }
      }
    case REQUEST_RECORD_DETAILS:
      // For now, no state is updated when we start loading a record.
      return state;
    case SET_RECORD_DETAILS:
      return {
        ...state,
        recordDetails: {
          ...state.recordDetails,
          [action.recordId]: action.payload
        }
      }
    case SET_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          currPage: action.pageNum
        }
      }
    case CLEAR_RECORDS:
      return {
        ...state,
        records: null,
        recordDetails: {},
      }
    default:
      return state;
  }
}

export default diseaseRecordsReducer;

