
/**
 * Base class for all known exceptions thrown by AIDO4Crops.
 */
export class AIDO4CropsException extends Error {
  constructor(message) {
    super(message)

    this.name = this.constructor.name
  }
}

/**
 * Thrown when the UI tries to find a property for which it does not have a valid
 * definition.
 */
export class UnkownPropertyDefinition extends AIDO4CropsException {};
