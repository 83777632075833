
export default function LoadingSpinner() {
  return (
    <div className="spinner-border text-secondary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
}


export function SmallLoadingSpinner() {
  return (
    <div className="spinner-border text-secondary" style={{ "width": "10px", "height": "10px" }} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
}
