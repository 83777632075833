
import { Component } from "react";

import MarkerClusterGroup from 'react-leaflet-markercluster';

import {
  MapContainer,
  TileLayer,
  Marker,
  FeatureGroup,
  Popup
} from 'react-leaflet';

import L from 'leaflet';

require('react-leaflet-markercluster/dist/styles.min.css'); // inside .js file


/**
 * Defines how to render a single point on the map for an occurrence series.
 */
export class OccurrencesSeriesPoint extends Component {
  render() {
    const {
      pointData,
      seriesData,
      // ⬆ filtering out props that `button` doesn’t know what to do with.
      ...rest
    } = this.props;

    const markerHtmlStyles = `
          background-color: ${this.props.seriesData.color};
          width: 2rem;
          height: 2rem;
          display: block;
          left: -0.5rem;
          top: -0.5rem;
          position: relative;
          border-radius: 3rem 3rem 0;
          transform: rotate(45deg);
          border: 2px solid #000000`

    const icon = L.divIcon({
                 className: "occurrence-marker",
                 iconAnchor: [0, 24],
                 labelAnchor: [-6, 0],
                 popupAnchor: [0, -36],
                 html: `<span style="${markerHtmlStyles}" />`
    })

    const locationPopup = pointData.location.name !== undefined
      ? (
        <Popup>
          <h6>{ pointData.location.name }</h6>
          <p>Date: { pointData.date.date.split("T")[0] }</p>
        </Popup>
      ) : (
        <Popup>
          <p>Date: { pointData.date.date.split("T")[0] }</p>
        </Popup>
      )
    
    return (
      <Marker
        position={[pointData.location.lat, pointData.location.lon ]}
        icon={icon}
        { ...rest }
      >
        { locationPopup }
      </Marker>
    );
  }
}

/**
 * Defines how to render a single value for a disease record's property.
 */
export class OccurrencesMap extends Component {
  render() {
    return (
      <MapContainer center={[0, 0]} zoom={1}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        { this.props.children }
      </MapContainer>
    );
  }
}
