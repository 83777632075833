
import axios from 'axios';


const ACTION_NS = "composite_series";
export const SET_AGGREGATE_RECORD_SERIES = `${ACTION_NS}/set_aggregate_record_series`;


/**
 * Get the composite series data for the given record.
 * 
 * This function assumes that the record details have already been pulled
 * down for the given record.
 * 
 * `recordId` is the ID of the disease record for which we should pull the time
 *    series data.
 */
 export function getCompositeOccurrenceSeriesForRecord(recordId) {
  return async (dispatch, getState) => {
    let currState = getState();

    // Get the list of series that are included in this record.
    const record_details = currState.diseaseRecordsReducer.recordDetails[recordId];
    const seriesIds = record_details.series.map((cur_series_spec) => cur_series_spec.id)
    // Thanks to [here](https://stackoverflow.com/a/63402277/5046197) for the idea.
    const seriesParamsString = seriesIds.map((curId) => `series=${curId}`).join("&");

    const recordsResponse = await axios.get(
      `/api/composite-series/?groupby=date&agg=size&${seriesParamsString}`
    );

    // Store the data.
    dispatch({type: SET_AGGREGATE_RECORD_SERIES, recordId, payload: recordsResponse.data})
  }
}
