
import { Component, Fragment } from "react";
import Plot from "../utils/Plotly";
import { connect } from "react-redux";

import LoadingSpinner from "../utils/LoadingSpinner";
import { scoringAlgorithmStatus } from "../redux/diseaseRecords/Actions";


/**
 * Displays a table for the scored properties.
 */
function ScoreTable(props) {
  const {
    propertyScoreDetails,
    totalScore,
    ...other
  } = props;

  let propertyDisplays = [];
  for(let property of propertyScoreDetails) {
    for(let currentValue of property.values) {
      propertyDisplays.push(
        <tr key={ property.propName }>
          <td>{ property.propName }</td>
          <td>{ currentValue }</td>
          <td>{ property.score }</td>
          <td>{ property.weight }</td>
          <td>{ property.weightedScore }</td>
        </tr>
      );
    }
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Property</th>
          <th scope="col">Record Value</th>
          <th scope="col">Score</th>
          <th scope="col">Weight</th>
          <th scope="col">Weighted Score</th>
        </tr>
      </thead>

      <tbody>
        { propertyDisplays }

        <tr>
          <th>Total score</th>
          <th></th>
          <th></th>
          <th></th>
          <th>{ totalScore.toFixed(2) }</th>
        </tr>
      </tbody>
    </table>
  );
}


/**
 * Displays a radar plot of the scored properties.
 */
function ScoreRadarPlot(props) {
  const {
    propertyScoreDetails,
    ...other
  } = props;

  let propNames = propertyScoreDetails.map((currScore) => currScore.propName);
  let propScores = propertyScoreDetails.map((currScore) => {
      const calculatedScore = parseFloat(currScore.weightedScore);
      if(isNaN(calculatedScore)) {
        return 0;
      } else {
        return parseFloat(currScore.weightedScore).toFixed(2);
      }
    }
  );
  let propWeights = propertyScoreDetails.map((currScore) => parseFloat(currScore.weight).toFixed(2));
  let couldCalculateScore = propertyScoreDetails.map(
    (currScore) => currScore.scoreIsDefined ? parseFloat(currScore.weight).toFixed(2) : 0
  );

  const maxPossibleScores = {
    type: "scatterpolar",
    r: propWeights,
    theta: propNames,
    name: "Weight",
    marker: {
      color: "#1f77b4"
    },
  }

  const scores = {
    type: "scatterpolar",
    r: propScores,
    theta: propNames,
    fill: "toself",
    name: "Score",
    marker: {
      color: "#ff7f0e"
    },
  }

  const couldCacluate = {
    type: "scatterpolar",
    r: couldCalculateScore,
    theta: propNames,
    fill: "toself",
    fillcolor: 'rgba(129, 149, 181,0.5)',
    marker: {
      color: 'rgba(100,100,100,0)'
    },
    name: "Can score",
  }

  return (
    <Plot
      data={[
        couldCacluate,
        maxPossibleScores,
        scores
      ]}
      layout={ {
        polar: {
          radialaxis: {
            visible: true,
            range: [0, Math.max(propWeights)]
          },
          angularaxis: {
            direction: "clockwise"
          }
        }
      } }
      // Thanks [here](https://codepen.io/nicolaskruchten/pen/ERgBZX) for the
      // responsiv plot recipe.
      useResizeHandler={true}
      style={{width: "100%", height: "100%"}}
    />
  );
}


/**
 * Defines how to build a list of values that should be displayed in the features/properties listing. Adds
 * information about how each of the displayed properties have been scored.
 * 
 * `propertiesToRender` is a list of property values containing the properties that should be displayed
 *    in tihs value collection.
 */
class ScoredPropertyView extends Component {
  render() {
    const browseOnly = this.props.scoringResultsStatus === scoringAlgorithmStatus.BROWSE_ONLY

    if(browseOnly) {
      return (
        <Fragment>
          <h5>Property values</h5>
          { this.renderData() }
        </Fragment>
      );
    } else if(this.props.scoringResultsStatus == scoringAlgorithmStatus.COMPLETE) {
      return (
        <Fragment>
          <h5>Scoring results <br/><small>How was the score calculated?</small></h5>
          { this.renderData() }
        </Fragment>
      );
    } else {
      return (
        <LoadingSpinner/>
      );
    }
  }

  renderData() {
    const {
      recordSummary,
      propertyDefinitions,
      propertiesToRender,
      ...other
    } = this.props;

    // Sort properties by weight.
    const propertiesSortedByWeight = propertiesToRender.sort(
      (curProperty, nextProperty) => (
        recordSummary.scoreBreakdown[propertyDefinitions[nextProperty.definition].prop.ref_id].weight - 
        recordSummary.scoreBreakdown[propertyDefinitions[curProperty.definition].prop.ref_id].weight
      )
    )

    let propertyScoreDetails = [];

    // Search specifically for the occurrence count and duration scores and display that.
    let occurrenceCountScore = recordSummary.scoreBreakdown["occurrence count"];
    let scoreIsDefined = !(occurrenceCountScore.score === null || occurrenceCountScore.score === undefined);
    let score = scoreIsDefined ? occurrenceCountScore.score.toFixed(2) : "---";
    let weight = occurrenceCountScore.weight.toFixed(2);
    let weightedScore = occurrenceCountScore.score ? (score * weight).toFixed(2) : "---";
    propertyScoreDetails.push(
      {
        propName: "Occurrence Count",
        scoreIsDefined: scoreIsDefined,
        score: score,
        weight: weight,
        weightedScore: weightedScore,
        values: ["---"]
      }
    )

    // Search specifically for the occurrence count and duration scores and display that.
    let durationScore = recordSummary.scoreBreakdown["duration"];
    scoreIsDefined = !(durationScore.score === null || durationScore.score === undefined);
    score = scoreIsDefined ? durationScore.score.toFixed(2) : "---";
    weight = durationScore.weight.toFixed(2);
    weightedScore = durationScore.score ? (score * weight).toFixed(2) : "---";
    propertyScoreDetails.push(
      {
        propName: "Duration",
        scoreIsDefined: scoreIsDefined,
        score: score,
        weight: weight,
        weightedScore: weightedScore,
        values: ["---"]
      }
    )

    // Add the rest of the props.
    propertyScoreDetails = propertyScoreDetails.concat(propertiesSortedByWeight.map((property) => {
      let scoreObject = recordSummary.scoreBreakdown[propertyDefinitions[property.definition].prop.ref_id];

      let propName = propertyDefinitions[property.definition].prop.name;
      let scoreIsDefined = !(scoreObject.score === null || scoreObject.score === undefined);
      let score = scoreIsDefined ? scoreObject.score.toFixed(2) : "---";
      let weight = scoreObject.weight.toFixed(2);
      let weightedScore = scoreObject.score ? (score * weight).toFixed(2) : "---";

      let values = property.values.map((currValue) => currValue.value);

      return {
        propName: propName,
        scoreIsDefined: scoreIsDefined,
        score: score,
        weight: weight,
        weightedScore: weightedScore,
        values: values
      }
    }));

    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            <ScoreRadarPlot propertyScoreDetails={ propertyScoreDetails }/>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ScoreTable
              propertyScoreDetails={ propertyScoreDetails }
              totalScore={ recordSummary.score }
            />
          </div>
        </div>
      </Fragment>
    )
  }
}


function mapStateToProps(state) {
  return {
    scoringResultsStatus: state.diseaseRecordsReducer.scoringStatus
  };
};

export default connect(
  mapStateToProps
)(ScoredPropertyView);
